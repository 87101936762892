import { Transition } from "@headlessui/react";
import React, { createContext, FC, ReactNode, useEffect, useState } from "react";

import SnackBar from "@/components/SnackBar";
import { Warning } from "@/components/SnackBar/styles";
import { SnackType } from "@/components/SnackBar/type";

interface Props {
  children?: ReactNode;
}
interface AlertProps {
  message: string;
  type: SnackType;
}
type alertContextType = {
  showAlert: ({ message, type }: AlertProps) => void;
};

const alertContextDefaultValue: alertContextType = {
  showAlert: () => {},
};

export const AlertContext = createContext<alertContextType>(alertContextDefaultValue);

export const AlertProvider: FC<Props> = ({ children }: Props) => {
  const [message, setMessage] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [type, setType] = useState<SnackType>("error");

  const showAlert = ({ message, type = "error" }: AlertProps) => {
    setMessage(message);
    setType(type);
    if (message !== "") {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setIsOpen(false);
      }, 5000);
    }
  }, [isOpen]);

  return (
    <AlertContext.Provider
      value={{
        showAlert,
      }}
    >
      <Transition
        show={isOpen}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Warning>
          <SnackBar
            type={type}
            message={message}
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
          />
        </Warning>
      </Transition>
      {children}
    </AlertContext.Provider>
  );
};
