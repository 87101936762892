import { router } from "@inertiajs/react";
import React from "react";

import SimpleButton from "@/components/Buttons/SimpleButton";
import FaqCardComponent from "@/components/Cards/FaqComponent";
import Layout from "@/components/Layout";
import Typography from "@/components/Typography";
import { HeaderInfo, ListSection, PageContainer } from "@/constants/listIndexStyle";

import PercentageLetterCard from "./PercentageLetterCard";

const PercentageLetters = ({ power_plant, letters }: any) => {
  return (
    <Layout>
      <PageContainer>
        <div className="max-w-60">
          <SimpleButton
            onClick={() => router.get(`/manager/power-plants`)}
            variant="back"
            label="Voltar"
          />
        </div>
        <div className="flex justify-between">
          <div className="flex flex-col">
            <Typography variant="h3" color="black">
              Cartas de rateio
            </Typography>
            <Typography variant="h5" color="grey400">
              {power_plant.name}
            </Typography>
          </div>
          <div className="w-64">
            <SimpleButton
              variant="simple"
              label="Criar carta de Rateio"
              onClick={() => router.get(`/manager/percentage-letter/new/${power_plant.id}`)}
            />
          </div>
        </div>

        <ListSection>
          <Typography variant="h6">Lista de cartas</Typography>
        </ListSection>

        <HeaderInfo>
          {letters.length > 0 ? (
            <>
              {letters.map((letter, index) => (
                <PercentageLetterCard
                  key={index}
                  name={letter.power_plant_name}
                  startDate={letter.start_date}
                  onClick={() => router.get(`/manager/percentage-letter/${letter.id}`)}
                  active={letter.active}
                />
              ))}
            </>
          ) : (
            <Typography variant="h6">Não foram criadas carta de rateio para esse usina</Typography>
          )}
        </HeaderInfo>

        <div className="mt-10">
          <FaqCardComponent variant="questionSection" />
        </div>
      </PageContainer>
    </Layout>
  );
};

export default PercentageLetters;
