import { Link, router, useForm } from "@inertiajs/react";
import React, { FormEvent, useContext, useState } from "react";
import { BsCheck } from "react-icons/bs";

import SimpleButton from "@/components/Buttons/SimpleButton";
import { StatusType } from "@/components/Buttons/SimpleButton/types";
import Logo from "@/components/Logo";
import TextFieldComponent from "@/components/TextField";
import Typography from "@/components/Typography";
import { AlertContext } from "@/contexts/Alert";

import { Access, Container, Form, Img, Infos, LogoContainer, PasswordInfos } from "./styles";

export default function SignIn() {
  const { showAlert } = useContext<any>(AlertContext);
  const [buttonStatus, setButtonStatus] = useState<StatusType>("enable");

  const { data, setData, post } = useForm({
    email: "",
    password: "",
    remember: false,
  });

  const submitUserData = (e: FormEvent) => {
    e.preventDefault();
    setButtonStatus("loading");
    post("/new_users/login", {
      onFinish: () => {
        setData("password", "");
        setButtonStatus("enable");
      },
      onSuccess: () => {
        showAlert({
          message: "Login efetuado com sucesso",
          type: "success",
        });
      },
      onError: (e) => {
        showAlert({
          message: e,
          type: "error",
        });
      },
    });
  };

  return (
    <Container>
      <div className="grid h-full grid-cols-1 justify-between gap-2 md:grid-cols-3">
        <Infos className="col-span-2 grid grid-cols-1 md:grid-cols-3">
          <LogoContainer onClick={() => router.get("/")}>
            <Logo size="h5" />
          </LogoContainer>
          <Access className="col-span-2 mx-auto w-full">
            <Form onSubmit={submitUserData} className="w-full">
              <div className="header">
                <Typography variant="h3" color="grey800">
                  Acessar
                </Typography>
                <Typography variant="pLarge" color="grey500">
                  Acesse sua conta de luz Geedee.
                </Typography>
              </div>
              <div className="forms-input">
                <div className="input">
                  <TextFieldComponent
                    disabled={false}
                    label="Seu e-mail"
                    error={false}
                    required
                    type="email"
                    value={data.email}
                    onChange={(e) => setData("email", e.target.value)}
                  />
                </div>
                <div className="input">
                  <TextFieldComponent
                    disabled={false}
                    label="Sua senha"
                    error={false}
                    password={true}
                    value={data.password}
                    required
                    onChange={(e) => setData("password", e.target.value)}
                  />
                </div>
              </div>
              <PasswordInfos>
                <div className="checkbox">
                  <label className="container">
                    <input
                      type="checkbox"
                      value={data.remember.toString()}
                      onChange={(e) => setData("remember", e.target.checked)}
                    />
                    <span>
                      <BsCheck />
                    </span>
                  </label>
                  <Typography variant="pLarge" color="grey700">
                    Lembrar senha
                  </Typography>
                  <div className="remember-password">
                    <Typography variant="link" color="black">
                      <Link href={`/new_users/password/new`}> Esqueci minha Senha </Link>
                    </Typography>
                  </div>
                </div>
              </PasswordInfos>

              <div className="button">
                <SimpleButton variant="cta" status={buttonStatus} label="Acessar Geedee" />
              </div>
            </Form>
            {/*<SignUp>*/}
            {/*  <Typography variant="pLarge" color="grey800">*/}
            {/*    Não possui conta?*/}
            {/*  </Typography>*/}
            {/*  <div className="signup-page">*/}
            {/*    <Typography variant="link" color="black">*/}
            {/*      Cadastre-se*/}
            {/*    </Typography>*/}
            {/*    <FiArrowRight className="icon" />*/}
            {/*  </div>*/}
            {/*</SignUp>*/}
          </Access>
        </Infos>
        <Img className="invisible w-full md:visible">
          <div className="image w-full" />
        </Img>
      </div>
    </Container>
  );
}
