import { Popover } from "@headlessui/react";
import { router } from "@inertiajs/react";
import React, { useContext, useState } from "react";
import { BsQuestion } from "react-icons/bs";

import ButtonIcon from "@/components/Buttons/SimpleButton";
import BillsToPay from "@/components/Cards/BillsToPay";
import FaqCardComponent from "@/components/Cards/FaqComponent";
import { CardValue } from "@/components/Cards/Value";
import Footer from "@/components/Footer";
import PaymentModal from "@/components/Modal/PaymentModal";
import Typography from "@/components/Typography";
import { AlertContext } from "@/contexts/Alert";
import { dateFormat, monthFormat, monthYearFormat } from "@/utils/dateFormater";
import moneyFormatter, { moneyFormatterWithoutCurrency } from "@/utils/moneyFormat";
import numberFormatter from "@/utils/numberFormatter";

import {
  AccountTimeDiv,
  BillDate,
  BillsCard,
  BreakDiv,
  ButtonDiv,
  DatesDiv,
  DatesSecondDiv,
  EconomyCard,
  EconomyDiv,
  EconomyFirstCard,
  EconomyInfoDiv,
  EconomySecondCard,
  InformationBox,
  InformationLinedDiv,
  Line,
  Page,
  SecondButtonDiv,
  ThirdInfoDiv,
  ThourthInfoDiv,
  ValuesDiv,
} from "./styles";

const InternalBills = ({ bill, enable_payment_modal, bank_info }: any) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const porcentageGeedee = () => bill.discount_percentage * 100;
  const { showAlert } = useContext<any>(AlertContext);

  const spareTreesText = () => {
    if (bill.spared_trees > 1) {
      return "árvores mantidas em solo amazônico";
    } else {
      return "árvore mantida em solo amazônico";
    }
  };

  const paymentStatusToVariant = () => {
    if (bill.payment_status === "not_paid") {
      return "pay";
    } else if (bill.payment_status === "paid") {
      return "in-day";
    } else {
      return "late";
    }
  };

  const navigateToPay = () => {
    if (enable_payment_modal) {
      setModalIsOpen(true);
    } else {
      if (bill.boleto_pdf !== "No file found") {
        window.open(bill.boleto_pdf, "_blank");
      } else {
        showAlert({
          message: "Boleto indisponível no momento.",
          type: "error",
        });
      }
    }
  };

  return (
    <>
      <BillsCard>
        <ButtonDiv>
          <ButtonIcon
            onClick={() => router.get("/consumer/bills")}
            variant="simple"
            label="Voltar para contas"
          />
        </ButtonDiv>

        <div className="grid grid-cols-1 justify-between pt-5 md:grid-cols-2">
          <div className="col">
            <BillDate>
              <AccountTimeDiv>
                <Typography variant="overline" color="primary200">
                  CONTA DE
                </Typography>
                <Typography variant="h3" color="white">
                  {monthYearFormat(bill.reference_month)}
                </Typography>
              </AccountTimeDiv>
            </BillDate>
          </div>
          <div className="col">
            <DatesSecondDiv>
              <BreakDiv>
                <Typography variant="overline" color="primary200">
                  Nº DE DIAS
                </Typography>
                <Typography variant="pLarge" color="white">
                  30 dias
                </Typography>
              </BreakDiv>
              <Line />
              <BreakDiv>
                <Typography variant="overline" color="primary200">
                  VENCIMENTO
                </Typography>
                <Typography variant="pLarge" color="white">
                  {dateFormat(bill.due_date)}
                </Typography>
              </BreakDiv>
            </DatesSecondDiv>
          </div>
        </div>

        <div className="grid grid-cols-1 justify-between pt-10 md:grid-cols-2">
          <ValuesDiv>
            <CardValue
              variant="money"
              value={moneyFormatterWithoutCurrency(bill.price)}
              lastMonthValue={false}
            />
          </ValuesDiv>
          <DatesDiv>
            <CardValue
              variant="energy"
              value={numberFormatter(bill.compensated_kwh)}
              lastMonthValue={false}
            />
          </DatesDiv>
        </div>

        <div className="mt-8">
          <BillsToPay
            variant={paymentStatusToVariant()}
            onClick={navigateToPay}
            date={bill.due_date}
          />
        </div>
      </BillsCard>

      <ThirdInfoDiv>
        <div className="grid grid-cols-1 justify-between gap-4 pt-5 md:grid-cols-2">
          <div>
            <EconomyInfoDiv>
              <Typography variant="h6" color="white">
                Descubra quanto economizou
              </Typography>
              <Typography variant="h6" color="white">
                :
              </Typography>
              <Typography variant="h6" color="white">
                {monthFormat(bill.reference_month)}
              </Typography>
            </EconomyInfoDiv>
            <EconomyDiv>
              <EconomyFirstCard>
                <Typography variant="overline" color="white">
                  PREÇO SEM GEEDEE
                </Typography>
                <Typography variant="h6" color="white">
                  {moneyFormatter(bill.price_without_geedee)}
                </Typography>
              </EconomyFirstCard>
              <EconomySecondCard>
                <Typography variant="overline" color="white">
                  GEEDEE
                </Typography>
                <Typography variant="h6" color="white">
                  {moneyFormatter(bill.price)}
                </Typography>
              </EconomySecondCard>
              <EconomyCard>
                <Typography variant="overline" color="white">
                  ECONOMIA
                </Typography>
                <Typography variant="h6" color="white">
                  {moneyFormatter(bill.discount)}
                </Typography>
              </EconomyCard>
              <SecondButtonDiv>
                {bill.status !== "paid" && (
                  <ButtonIcon
                    variant="simple"
                    label="Pagar conta agora :)"
                    onClick={navigateToPay}
                  />
                )}
              </SecondButtonDiv>
            </EconomyDiv>
          </div>

          <div className="row content-evenly justify-evenly">
            <InformationBox>
              <Typography variant="h6" color="white">
                Você deixou de emitir
              </Typography>
              <Typography variant="h2" color="white">
                {bill.compensed_co2.toFixed(0)}Kg de CO2
              </Typography>
              <Typography variant="h6" color="white">
                Que equivalem a
              </Typography>
              <Typography variant="h2" color="white">
                {bill.spared_trees.toFixed(0)} {spareTreesText()}
              </Typography>
            </InformationBox>
          </div>
        </div>
      </ThirdInfoDiv>

      <ThourthInfoDiv>
        <Typography variant="h6" color="white">
          Transparência Geedee
        </Typography>
        <InformationLinedDiv>
          <Typography variant="btnLarge" color="white">
            Energia Elétrica Injetada
          </Typography>
          <Typography variant="btnLarge" color="white">
            {numberFormatter(bill.compensated_kwh)} KWh
          </Typography>
        </InformationLinedDiv>
        <InformationLinedDiv>
          <Typography variant="pLarge" color="white">
            Tarifa da distribuidora com imposto
          </Typography>
          <Typography variant="pLarge" color="white">
            {moneyFormatter(bill.distributor_kwh_price)} / kWh
          </Typography>
        </InformationLinedDiv>
        <InformationLinedDiv>
          <div className="items-left flex flex-col gap-2 lg:flex-row lg:items-center">
            <Typography variant="pLarge" color="white">
              Tarifa Compensável
            </Typography>
            <Popover className="z-100 relative">
              <Popover.Button>
                <BsQuestion color="white" size={22} />
              </Popover.Button>

              <Popover.Panel className="z-100 absolute w-64 rounded bg-gray-900 p-5 lg:w-96">
                <Typography variant="pLarge" color="white">
                  A tarifa compensável é definida como a tarifa da distribuidora subtraída do ICMS
                  da TUSD
                </Typography>
              </Popover.Panel>
            </Popover>
          </div>

          <Typography variant="pLarge" color="white">
            {moneyFormatter(bill.expensable_tariff_cents)} / kWh
          </Typography>
        </InformationLinedDiv>
        <InformationLinedDiv>
          <Typography variant="pLarge" color="white">
            Desconto (sobre a conta da distribuidora)
          </Typography>
          <Typography variant="pLarge" color="white">
            {porcentageGeedee().toFixed(2)}%
          </Typography>
        </InformationLinedDiv>
        {/* <InformationLinedDiv>
          <Typography variant="pLarge" color="white">
            Tarifa Geedee
          </Typography>
          <Typography variant="pLarge" color="white">
            {moneyFormatter(bill.total_kwh_price)} / kWh
          </Typography>
        </InformationLinedDiv> */}
        <InformationLinedDiv>
          <Typography variant="btnLarge" color="white">
            Total
          </Typography>
          <Typography variant="btnLarge" color="white">
            {moneyFormatter(bill.price)}
          </Typography>
        </InformationLinedDiv>
      </ThourthInfoDiv>
      <Page>
        <FaqCardComponent variant="questionSection" />
      </Page>
      <Footer />

      <PaymentModal isOpen={modalIsOpen} setIsOpen={setModalIsOpen} bankInfo={bank_info} />
    </>
  );
};

export default InternalBills;
