import { router } from "@inertiajs/react";
import React, { useEffect, useState } from "react";
import { BiSearchAlt } from "react-icons/bi";

import { BigNumber } from "@/components/Cards/BigNumber";
import FaqCardComponent from "@/components/Cards/FaqComponent";
import Layout from "@/components/Layout";
import Typography from "@/components/Typography";
import {
  FiltersContainer,
  HeaderInfo,
  ListSection,
  PageContainer,
} from "@/constants/listIndexStyle";
import { SearchForm } from "@/pages/manager/power-plants/style";
import { bigNumberFormatter } from "@/utils/bigNumberFormatter";

import PowerPlantCard from "./PowerPlantCard";

const PowerPlants = ({ currentUser, powerPlants, powerPlantsInfo }: any) => {
  const [filteredPowerPlants, setFilteredPowerPlants] = useState<Array<any>>([]);
  const [search, setSearch] = useState({
    name: "",
  });
  // Filter powerPlants
  useEffect(() => {
    setFilteredPowerPlants(powerPlants);
  }, [powerPlants]);

  // Filter powerPlants
  useEffect(() => {
    const getData = setTimeout(() => {
      router.reload({ only: ["powerPlants"], data: { name: search.name } });
    }, 500);
    return () => clearTimeout(getData);
  }, [currentUser?.id, search]);

  return (
    <Layout>
      <PageContainer>
        <Typography variant="h3" color="black">
          Usinas
        </Typography>
        <HeaderInfo>
          <div className="grid w-full grid-cols-1 justify-between gap-4 pt-5 md:grid-cols-3">
            <BigNumber
              infoTitle="Número de Usinas"
              info={bigNumberFormatter(powerPlantsInfo.power_plants_count)}
            />
            <BigNumber
              infoTitle="Capacidade Instalada"
              info={bigNumberFormatter(powerPlantsInfo.installed_capacity)}
            />
            <BigNumber
              infoTitle="Número de Distribuidoras"
              info={bigNumberFormatter(powerPlantsInfo.distributor_count)}
            />
          </div>
        </HeaderInfo>
        <ListSection>
          <Typography variant="h6">Lista de usinas</Typography>
          <FiltersContainer>
            <SearchForm className="search" onSubmit={(e) => e.preventDefault()}>
              <input
                type="text"
                value={search.name}
                onChange={(e) => setSearch({ ...search, name: e.target.value })}
              />
              <button className="search-icon">
                <BiSearchAlt />
              </button>
            </SearchForm>
          </FiltersContainer>
        </ListSection>

        <HeaderInfo>
          {filteredPowerPlants.length > 0 ? (
            <>
              {filteredPowerPlants.map((powerPlant, index) => (
                <PowerPlantCard
                  key={index}
                  id={powerPlant.id}
                  name={powerPlant.name}
                  energy={`${bigNumberFormatter(powerPlant.installed_capacity)} kWh`}
                  onClick={() => router.get(`/manager/internal-power-plants/${powerPlant.id}`)}
                  percentageLetters={powerPlant.has_percentage_letters}
                />
              ))}
            </>
          ) : (
            <Typography variant="h6">Não houve resultado para sua filtragem</Typography>
          )}
        </HeaderInfo>

        <div className="mt-10">
          <FaqCardComponent variant="questionSection" />
        </div>
      </PageContainer>
    </Layout>
  );
};

export default PowerPlants;
