import React from "react";
import { IoCalendar, IoCheckmark } from "react-icons/io5";
import { TbSunrise } from "react-icons/tb";

import SimpleButton from "@/components/Buttons/SimpleButton";
import ContaInfos from "@/components/Cards/ContaInfos";

import { PowerPlantCardContainer } from "../power-plants/style";
import { PercentageLetterCardProps } from "./types";

const PercentageLetterCard: React.FC<PercentageLetterCardProps> = ({
  name,
  startDate,
  active,
  onClick,
}) => {
  const date = new Date(startDate);
  return (
    <PowerPlantCardContainer>
      <div className="numbers">
        <ContaInfos title="NOME DA USINA" info={name} image={<TbSunrise />} />
        <div className="line" />
        <ContaInfos
          title="DATA DE INÍCIO"
          info={`${date.getDate() + 1}/${
            date.getMonth() + 1 > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
          }/${date.getFullYear()}`}
          image={<IoCalendar />}
        />
        <div className="line" />
        <ContaInfos title="STATUS" info={active ? "Ativo" : "Inativo"} image={<IoCheckmark />} />
      </div>
      <div className="details-button">
        <SimpleButton variant="simple" label="Detalhes" onClick={onClick} />
      </div>
    </PowerPlantCardContainer>
  );
};

export default PercentageLetterCard;
