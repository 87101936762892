import styled from "styled-components";

import theme from "@/constants/theme";

export const Container = styled.div`
  width: 100%;
  height: auto;

  .date {
    width: auto;
    height: auto;
  }

  .value {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    padding: 24px 0px 24px 0px;

    .icon-circle {
      min-width: 56px;
      min-height: 56px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: ${theme.colors.secondary300};
      margin-right: 32px;
      font-size: 25px;
      color: ${theme.colors.black};
    }
  }

  .last-month {
    width: auto;
    height: auto;
    padding-left: 90px;
  }

  @media (max-width: 450px) {
    .value {
      width: 100%;
      height: 100px;
      padding: 0px;

      .icon-circle {
        min-width: 48px;
        min-height: 48px;
      }
    }
  }
`;
