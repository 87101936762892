import React from "react";
import styled from "styled-components";

import Typography from "@/components/Typography";
import { VariantType } from "@/components/Typography/types";

const LogoContainer = styled.div`
  width: 80px;
  height: 30px;
  cursor: pointer;
`;

const Logo = ({
  size = "h6",
  marginClass = "ml-8",
}: {
  size?: VariantType;
  marginClass?: string;
}) => (
  <LogoContainer className={marginClass}>
    <Typography variant={size} color="primary400">
      geedee
    </Typography>
  </LogoContainer>
);
export default Logo;
