import styled from "styled-components";

import theme from "@/constants/theme";
import { colorType } from "@/constants/types";

import { StatusType, variantColor, variantHeight, VariantType } from "./types";

interface SimpleButtonProps {
  variant: VariantType;
  status?: StatusType;
  label: string;
  $backgroundColor?: colorType;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

const height = ({ variant }: SimpleButtonProps) => {
  return variantHeight[variant];
};

const backgroundColor = ({ status, $backgroundColor }: SimpleButtonProps) => {
  if ($backgroundColor) {
    return theme.colors[$backgroundColor];
  } else {
    if (status) {
      return variantColor[status];
    } else {
      return theme.colors.black;
    }
  }
};

const color = ({ status }: SimpleButtonProps) => {
  if (status == "disabled") {
    return theme.colors.grey500;
  } else {
    return theme.colors.black;
  }
};

const backgroundIcon = ({ status }: SimpleButtonProps) => {
  if (status == "disabled") {
    return theme.colors.grey300;
  } else {
    return theme.colors.white;
  }
};

export const Container = styled.button<SimpleButtonProps>`
  width: 100%;
  height: ${(props) => height(props)};
  background-color: ${(props) => backgroundColor(props)};
  border-radius: 56px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 16px 0px 21px;
  border: none;
  cursor: pointer;

  .info-button {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: ${(props) =>
      props.variant == "simple" || props.status == "loading" ? "center" : "flex-start"};
  }

  .icon-right {
    width: 20px;
    height: 20px;
    color: ${(props) => color(props)};
    font-size: 20px;
    background-color: ${(props) => backgroundIcon(props)};
    border-radius: 50%;
    margin-left: 32px;
  }

  .icon-left {
    padding-right: 18px;
    font-size: 20px;
    color: white;
    display: flex;
    align-items: center;
  }

  .loading-size {
    width: 60px;
  }
`;
