import { usePage } from "@inertiajs/react";
import axios from "axios";
import React, { MouseEvent, useContext, useRef, useState } from "react";
import { BiUser } from "react-icons/bi";
import { FiArrowLeft, FiArrowRight, FiX } from "react-icons/fi";
import { IoFlashOutline, IoHomeOutline } from "react-icons/io5";
import { Grid } from "react-loader-spinner";

import SimpleButton from "@/components/Buttons/SimpleButton";
import FaqCardComponent from "@/components/Cards/FaqComponent";
import Layout from "@/components/Layout";
import TextFieldComponent from "@/components/TextField";
import Typography from "@/components/Typography";
import theme from "@/constants/theme";
import { AlertContext } from "@/contexts/Alert";

import {
  Background,
  ChangePassword,
  ConsumerExtraInfos,
  ConsumerUnit,
  Container,
  Form,
  Infos,
  UserInfos,
} from "./styles";

function Info(title: string, info: string) {
  return (
    <div className="info">
      <Typography variant="overline" color="grey500">
        {title}
      </Typography>
      <Typography variant="h5">{info}</Typography>
    </div>
  );
}

export default function PageData() {
  const { currentUser }: any = usePage().props;
  const userType = currentUser?.role;
  const [passwordInfos, setPasswordInfos] = useState({
    oldPassword: "",
    newPassword: "",
    newPasswordRepeated: "",
  });
  const [changePasswordWindow, setChangePasswordWindow] = useState(false);

  const { showAlert } = useContext<any>(AlertContext);

  const carousel = useRef<HTMLDivElement>(null);

  function ChangePasswordRequisition(e: any) {
    e.preventDefault();
    axios
      .patch("/api/users/password", passwordInfos)
      .then(() => {
        showAlert({
          message: "Senha alterada com sucesso.",
          type: "success",
        });
      })
      .catch(() => {
        showAlert({
          message: "Erro ao alterar sua senha.",
          type: "error",
        });
      });
    setPasswordInfos({
      oldPassword: "",
      newPassword: "",
      newPasswordRepeated: "",
    });
    setChangePasswordWindow(!changePasswordWindow);
  }

  const ClickLeft = (e: MouseEvent) => {
    e.preventDefault();
    if (carousel.current != null) {
      carousel.current.scrollLeft -= carousel.current.offsetWidth;
    }
  };

  const ClickRight = (e: MouseEvent) => {
    e.preventDefault();
    if (carousel.current != null) {
      carousel.current.scrollLeft += carousel.current.offsetWidth;
    }
  };

  return (
    <Layout>
      <Container>
        <Infos>
          <div className="presentation">
            <div className="title">
              <Typography variant="h3">Meus dados</Typography>
            </div>
            <div className="subtitle">
              <Typography variant="pLarge">
                Você encontra os seus dados na conta Geedee e pode realizar algumas ações, como
                substituir sua senha.
              </Typography>
            </div>
          </div>
          <UserInfos>
            {!currentUser ? (
              <div className="loading-center">
                <div className="loading-size">
                  <Grid color={theme.colors.grey500} />
                </div>
              </div>
            ) : (
              <>
                <hr />
                <div className="user-type">
                  <div className="circle-icon">
                    <BiUser />
                  </div>
                  <Typography variant="pLarge">
                    {userType == "consumer"
                      ? "Responsável"
                      : userType == "admin"
                        ? "Administrador"
                        : userType == "manager" && "Gestor"}
                  </Typography>
                </div>
                {Info("NOME", currentUser?.name)}
                {userType == "consumer" || userType == "admin"
                  ? Info("CPF ou CNPJ", currentUser?.cpf)
                  : ""}
                <div className="email-phone">
                  {Info("E-MAIL", currentUser?.email)}
                  {userType == "consumer" || userType == "admin"
                    ? Info("TELEFONE", currentUser?.phone)
                    : ""}
                </div>
                {userType == "consumer" && (
                  <>
                    <hr />
                    <ConsumerExtraInfos>
                      <div className="user-type">
                        <div className="circle-icon">
                          <IoHomeOutline />
                        </div>
                        <Typography variant="pLarge">Endereço</Typography>
                      </div>
                      {Info("CEP", currentUser?.address.cep)}
                      {Info("ENDEREÇO", currentUser?.address.address)}
                      <div className="number-complement">
                        {Info("NÚMERO", currentUser?.address.number)}
                        {Info("COMPLEMENTO", currentUser?.address.complement)}
                      </div>
                      {Info("BAIRRO", currentUser?.address.neighborhood)}
                      <div className="city-state">
                        {Info("CIDADE", currentUser?.address.city)}
                        {Info("ESTADO", currentUser?.address.uf)}
                      </div>
                      <div className="card-one">
                        <FaqCardComponent
                          variant="dataSection"
                          buttonLabel="Mudar senha"
                          onClick={() => setChangePasswordWindow(!changePasswordWindow)}
                        />
                      </div>
                      <hr />
                      <div className="consumer-unit-header">
                        <div className="user-type">
                          <div className="circle-icon">
                            <IoFlashOutline />
                          </div>
                          <Typography variant="pLarge">
                            {currentUser.facilities.length > 1
                              ? "Unidades Consumidoras"
                              : "Unidade Consumidora"}
                          </Typography>
                        </div>
                        {currentUser.facilities.length > 1 && (
                          <div className="carousel-buttons">
                            <div className="button-left" onClick={(e) => ClickLeft(e)}>
                              <FiArrowLeft />
                            </div>
                            <div className="button-right" onClick={(e) => ClickRight(e)}>
                              <FiArrowRight />
                            </div>
                          </div>
                        )}
                      </div>
                      <ConsumerUnit ref={carousel}>
                        {currentUser.facilities.map((facility: any, index: number) => {
                          return (
                            <div className="facility-card-width" key={index}>
                              <div className="facility-card">
                                {Info("CEP", facility.address.cep)}
                                {Info("ENDEREÇO", facility.address.address)}
                                <div className="number-complement">
                                  {Info("NÚMERO", facility.address.number)}
                                  {Info("COMPLEMENTO", facility.address.complement)}
                                </div>
                                {Info("BAIRRO", facility.address.neighborhood)}
                                <div className="city-state">
                                  {Info("CIDADE", facility.address.city)}
                                  {Info("ESTADO", facility.address.uf)}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </ConsumerUnit>
                      <div className="card-two">
                        <div className="card-two-center">
                          <FaqCardComponent variant="email" />
                        </div>
                      </div>
                    </ConsumerExtraInfos>
                  </>
                )}
                {userType != "consumer" && (
                  <div className="card-one">
                    <FaqCardComponent
                      variant="dataSection"
                      onClick={() => setChangePasswordWindow(!changePasswordWindow)}
                      buttonLabel="Mudar senha"
                    />
                  </div>
                )}
                {userType == "manager" && (
                  <div className="card-two">
                    <div className="card-two-center">
                      <FaqCardComponent variant="email" />
                    </div>
                  </div>
                )}
              </>
            )}
          </UserInfos>
        </Infos>
      </Container>
      <Background $displayFlex={changePasswordWindow} />
      <ChangePassword $displayFlex={changePasswordWindow}>
        <div className="window">
          <div className="window-header">
            <div className="title-icon">
              <Typography variant="h3" color="white">
                Mudar senha
              </Typography>
              <div className="window-circle-icon">
                <FiX onClick={() => setChangePasswordWindow(!changePasswordWindow)} />
              </div>
            </div>
            <div className="subtitle">
              <Typography variant="pLarge" color="primary100">
                Você pode alterar sua senha conforme desejar, basta preencher os campos abaixo.
              </Typography>
            </div>
          </div>
          <Form onSubmit={ChangePasswordRequisition}>
            <div className="input">
              <TextFieldComponent
                disabled={false}
                label="Informe a senha antiga"
                error={false}
                password={true}
                value={passwordInfos.oldPassword}
                required
                onChange={(e) =>
                  setPasswordInfos({
                    ...passwordInfos,
                    oldPassword: e.target.value,
                  })
                }
              />
            </div>
            <div className="input">
              <TextFieldComponent
                disabled={false}
                label="Informe a nova senha"
                error={false}
                password={true}
                value={passwordInfos.newPassword}
                required
                onChange={(e) =>
                  setPasswordInfos({
                    ...passwordInfos,
                    newPassword: e.target.value,
                  })
                }
              />
            </div>
            <div className="input">
              <TextFieldComponent
                disabled={false}
                label="Repita a nova senha"
                error={false}
                pattern={passwordInfos.newPassword}
                password={true}
                value={passwordInfos.newPasswordRepeated}
                title="Verifique se as senhas são iguais."
                required
                onChange={(e) =>
                  setPasswordInfos({
                    ...passwordInfos,
                    newPasswordRepeated: e.target.value,
                  })
                }
              />
            </div>
            <div className="form-buttons">
              <div
                className="cancel-button"
                onClick={() => setChangePasswordWindow(!changePasswordWindow)}
              >
                <Typography variant="link" color="black">
                  Cancelar
                </Typography>
              </div>
              <div className="submit-button">
                <SimpleButton variant="simple" status={"enable"} label="Mudar senha" />
              </div>
            </div>
          </Form>
        </div>
      </ChangePassword>
    </Layout>
  );
}
