import styled from "styled-components";

import theme from "@/constants/theme";

export const Container = styled.div`
  width: 100%;
  height: 460px;
  background-color: ${theme.colors.primary500};
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  border-radius: 32px;

  .header {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .steps {
      min-width: 120px;
      height: auto;
      padding-left: 20px;

      .steps-position {
        width: auto;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 4px 13.5px 4px 13.5px;
        background-color: ${theme.colors.primary400};
        border-radius: 56px;
      }
    }
  }
`;
