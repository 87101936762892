export const nameFormater = (str: any) => {
  if (str) {
    const arr = str.split(" ");
    if (arr.length > 1) {
      return arr[0] + " " + arr[arr.length - 1];
    } else {
      return str;
    }
  } else {
    return "Não possui";
  }
};
