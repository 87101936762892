import theme, { styled } from "@/constants/theme";

import { colorType } from "../../constants/types";
import {
  variantsLineHeight,
  variantsSizes,
  variantsSizesResponsive,
  variantsWeight,
  VariantType,
} from "./types";

interface TypographyProps {
  variant: VariantType;
  color?: colorType;
  $cursorPointer?: boolean;
}

const color = ({ color = "black" }: TypographyProps) => {
  return theme.colors[color];
};

const size = ({ variant }: TypographyProps) => {
  return variantsSizes[variant];
};

const weight = ({ variant }: TypographyProps) => {
  return variantsWeight[variant];
};

const lineHeight = ({ variant }: TypographyProps) => {
  return variantsLineHeight[variant];
};

const sizeResponsive = ({ variant }: TypographyProps) => {
  return variantsSizesResponsive[variant];
};

export const Text = styled.span<TypographyProps>`
  font-family: "Inter", sans-serif;
  line-height: ${(props) => lineHeight(props)};
  color: ${(props) => color(props)};
  font-size: ${size};
  font-weight: ${weight};
  cursor: ${(props) => (props.onClick || props.$cursorPointer ? "pointer" : "")};

  @media (max-width: 450px) {
    font-size: ${sizeResponsive};
  }
`;
