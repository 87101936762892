import React from "react";
import { BiWallet } from "react-icons/bi";
import { IoFlashOutline } from "react-icons/io5";

import Typography from "@/components/Typography";

import { Container } from "./styles";
import { ValueProps } from "./types";

export const CardValue = ({ variant, lastMonthValue = true, value, month }: ValueProps) => {
  return (
    <Container>
      <div className="date">
        {variant == "money" ? (
          <>
            <Typography variant="h6" color="primary100">
              Conta Geedee: {month}
            </Typography>
          </>
        ) : (
          <Typography variant="h6" color="primary100">
            Energia Injetada
          </Typography>
        )}
      </div>
      <div className="value">
        <div className="icon-circle">{variant == "money" ? <BiWallet /> : <IoFlashOutline />}</div>
        <div className="number">
          {variant == "money" ? (
            <Typography variant="big" color="primary200">
              R${" "}
            </Typography>
          ) : (
            <></>
          )}
          <Typography variant="big" color="white">
            {value + " "}
          </Typography>
          {variant != "money" ? (
            <Typography variant="big" color="primary200">
              kWh
            </Typography>
          ) : (
            <></>
          )}
        </div>
      </div>
      {lastMonthValue && (
        <div className="last-month">
          <Typography variant="pLarge" color="white">
            {variant == "money"
              ? `R$ ${lastMonthValue} no mês anterior`
              : `${lastMonthValue} kWh no mês anterior`}
          </Typography>
        </div>
      )}
    </Container>
  );
};
