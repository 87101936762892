import React from "react";

import { ProgressBar, ProgressBarContainer } from "./styles";
import { ProgressBarProps } from "./types";

const ProgressBarComponent = ({ color, percentage }: ProgressBarProps) => {
  const [progressStyle, setProgressStyle] = React.useState<{ width: string }>();

  React.useEffect(() => {
    const width = `${percentage}%`;
    setProgressStyle({ width });
  }, [percentage]);

  return (
    <ProgressBarContainer>
      <ProgressBar color={color} style={{ width: progressStyle?.width }} />
    </ProgressBarContainer>
  );
};

export default ProgressBarComponent;
