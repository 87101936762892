import React, { useState } from "react";
import { HiOutlineEye, HiOutlineEyeOff } from "react-icons/hi";

import { Button, Field, HelperText, InputLabel, TextField } from "./styles";
import { TextFieldProps } from "./types";

const TextFieldComponent = ({
  disabled = false,
  error = false,
  password = false,
  label,
  helpText,
  ...props
}: TextFieldProps) => {
  const [showPassword, setShowPassword] = useState(!password);
  return (
    <Field>
      <TextField
        disabled={disabled}
        $error={error}
        {...props}
        placeholder=" "
        type={showPassword ? "text" : "password"}
      />
      <InputLabel $error={error}>{label}</InputLabel>
      <HelperText $error={error}>{helpText}</HelperText>

      {password && (
        <Button onClick={() => setShowPassword(!showPassword)}>
          {!showPassword ? <HiOutlineEyeOff size={30} /> : <HiOutlineEye size={30} />}
        </Button>
      )}
    </Field>
  );
};

export default TextFieldComponent;
