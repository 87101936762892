import { router } from "@inertiajs/react";
import React, { useState } from "react";
import { AiOutlineThunderbolt } from "react-icons/ai";
import { BsHouse } from "react-icons/bs";

// import { FaMoneyBillWaveAlt } from "react-icons/fa";
// import { TbWallet } from "react-icons/tb";
import SimpleButton from "@/components/Buttons/SimpleButton";
import BillsInfo from "@/components/Cards/BillsInfo";
import FaqCardComponent from "@/components/Cards/FaqComponent";
import Dropdown from "@/components/Dropdown";
import Footer from "@/components/Footer";
import Typography from "@/components/Typography";
import { monthFormat, monthYearFormat } from "@/utils/dateFormater";
import moneyFormatter from "@/utils/moneyFormat";
import numberFormatter from "@/utils/numberFormatter";
import { distribuitionStatus, OverdueStatus } from "@/utils/statusFormater";

import {
  AccountTimeDiv,
  BillsDiv,
  BreakDiv,
  ButtonContainer,
  ButtonDiv,
  CenterDiv,
  DatesSecondDiv,
  FiltersContainer,
  FirstDiv,
  HeaderInfo,
  // IconDiv,
  InfoIconDiv,
  // InformationBox,
  InformationBoxHide,
  InformationDiv,
  Line,
  ListSection,
  NameDiv,
  Page,
  // SecondDiv,
  // ThirdDiv,
  TitleDiv,
  // ValuesDiv,
} from "./styles";

const yearsOptions: string[] = ["2022", "2023", "2024"];
const statusOptions: string[] = ["Todas", "Em aberto", "Pago"];

const InternalConsumer = ({ consumer, billsInfo, page, totalPages }: any) => {
  const [facility, setFacility] = useState(consumer.facilities[0]);
  const [moreInfo, setMoreInfo] = useState(false);
  //const [billsInfo, setBillsInfo] = useState<any[]>([]);
  const today = new Date();
  const facilityOptions = consumer.facilities.map((f) => f.facility_code);

  const [filters, setFilters] = useState({
    year: String(today.getFullYear()),
    status: "",
  });

  const statusValues = { Todas: null, "Em aberto": "not_paid", Pago: "paid" };

  const changeFilters = ({ year = filters.year, status = filters.status }) => {
    page = 1;
    setFilters({ year, status });
    router.reload({
      data: {
        page: page,
        year: year,
        payment_status: statusValues[status] !== undefined ? statusValues[status] : status,
      },
    });
  };
  const showMore = () => page != totalPages && billsInfo.length != 0;

  // useEffect(() => {
  //   if (currentUser?.id) {
  //     Bills.all({
  //       userId: id,
  //       status: filters.status,
  //       year: filters.year,
  //       page: page,
  //     }).then((res) => {
  //       if (res.headers["current-page"] == "1") {
  //         setBillsInfo(res.data);
  //       } else {
  //         setBillsInfo((prevState) => [...prevState, ...res.data]);
  //       }
  //       setTotalPages(res.headers["total-pages"]);
  //     });
  //   }
  // }, [filters, id, page, currentUser]);
  const overdueInformation = OverdueStatus(consumer.overdue_status);
  const distribuitionInfo = distribuitionStatus(consumer.distribution_status);

  return (
    <div>
      <FirstDiv>
        <ButtonDiv>
          <SimpleButton
            onClick={() => router.get("/manager/consumers")}
            variant="simple"
            label="Voltar para clientes"
          />
        </ButtonDiv>

        <div className="grid grid-cols-1 justify-between pt-5 md:grid-cols-2">
          <div className="col">
            <NameDiv>
              <AccountTimeDiv>
                <Typography variant="overline" color="white">
                  NOME
                </Typography>
                <Typography variant="h3" color="white">
                  {consumer.name}
                </Typography>
              </AccountTimeDiv>
            </NameDiv>
          </div>
          <div className="col">
            <DatesSecondDiv>
              <BreakDiv>
                <Typography variant="overline" color="white">
                  SITUAÇÃO
                </Typography>
                <Typography variant="pLarge" color="white" />
                <div className={overdueInformation.backgroundColor}>
                  <Typography variant="pLarge" color={overdueInformation.color}>
                    {overdueInformation.label}
                  </Typography>
                </div>
              </BreakDiv>
              <BreakDiv>
                <Typography variant="overline" color="white">
                  STATUS
                </Typography>
                <Typography variant="pLarge" color="white" />
                <div className={distribuitionInfo.backgroundColor}>
                  <Typography variant="pLarge" color={distribuitionInfo.color}>
                    {distribuitionInfo.label}
                  </Typography>
                </div>
              </BreakDiv>
              <BreakDiv>
                <Typography variant="overline" color="white">
                  DATA DE ADESÃO
                </Typography>
                <Typography variant="pLarge" color="white">
                  {monthYearFormat(consumer.created_at)}
                </Typography>
              </BreakDiv>
            </DatesSecondDiv>
          </div>
        </div>
        {moreInfo && (
          <>
            <Line />
            <div>
              <TitleDiv>
                <InfoIconDiv>
                  <AiOutlineThunderbolt size={22} />
                </InfoIconDiv>
                <Typography variant="pLarge" color="white">
                  Responsável
                </Typography>
              </TitleDiv>
              <InformationDiv>
                <InformationBoxHide>
                  <BreakDiv>
                    <Typography variant="pLarge" color="white">
                      CPF OU CNPJ
                    </Typography>
                    <Typography variant="h5" color="white">
                      {consumer.cpf || consumer.cnpj}
                    </Typography>
                  </BreakDiv>
                </InformationBoxHide>
                <InformationBoxHide>
                  <BreakDiv>
                    <Typography variant="pLarge" color="white">
                      E-MAIL
                    </Typography>
                    <Typography variant="h5" color="white">
                      {consumer.email}
                    </Typography>
                  </BreakDiv>
                  <BreakDiv>
                    <Typography variant="pLarge" color="white">
                      TELEFONE
                    </Typography>
                    <Typography variant="h5" color="white">
                      {consumer.phone}
                    </Typography>
                  </BreakDiv>
                </InformationBoxHide>
              </InformationDiv>
            </div>
            <Line />
            <div>
              <TitleDiv>
                <InfoIconDiv>
                  <BsHouse size={22} />
                </InfoIconDiv>
                <Typography variant="pLarge" color="white">
                  Endereço
                </Typography>
              </TitleDiv>
              <InformationDiv>
                <BreakDiv>
                  <Typography variant="pLarge" color="white">
                    CEP
                  </Typography>
                  <Typography variant="h5" color="white">
                    {consumer.address.cep}
                  </Typography>
                </BreakDiv>
                <InformationBoxHide>
                  <BreakDiv>
                    <Typography variant="pLarge" color="white">
                      ENDEREÇO
                    </Typography>
                    <Typography variant="h5" color="white">
                      {consumer.address.address}
                    </Typography>
                  </BreakDiv>
                </InformationBoxHide>
                <InformationBoxHide>
                  <BreakDiv>
                    <Typography variant="pLarge" color="white">
                      NÚMERO
                    </Typography>
                    <Typography variant="h5" color="white">
                      {consumer.address.number}
                    </Typography>
                  </BreakDiv>
                  <BreakDiv>
                    <Typography variant="pLarge" color="white">
                      COMPLEMENTO
                    </Typography>
                    <Typography variant="h5" color="white">
                      {consumer.address.complement}
                    </Typography>
                  </BreakDiv>
                </InformationBoxHide>
                <InformationBoxHide>
                  <BreakDiv>
                    <Typography variant="pLarge" color="white">
                      BAIRRO
                    </Typography>
                    <Typography variant="h5" color="white">
                      {consumer.address.neighborhood}
                    </Typography>
                  </BreakDiv>
                </InformationBoxHide>
                <InformationBoxHide>
                  <BreakDiv>
                    <Typography variant="pLarge" color="white">
                      CIDADE
                    </Typography>
                    <Typography variant="h5" color="white">
                      {consumer.address.city}
                    </Typography>
                  </BreakDiv>
                  <BreakDiv>
                    <Typography variant="pLarge" color="white">
                      ESTADO
                    </Typography>
                    <Typography variant="h5" color="white">
                      {consumer.address.uf}
                    </Typography>
                  </BreakDiv>
                </InformationBoxHide>
              </InformationDiv>
            </div>
            <Line />
            {facility && (
              <div>
                <TitleDiv className="justify-between">
                  <div className="flex items-center justify-center gap-2">
                    <InfoIconDiv>
                      <AiOutlineThunderbolt size={22} />
                    </InfoIconDiv>
                    <Typography variant="pLarge" color="white">
                      Unidades consumidoras
                    </Typography>
                  </div>
                  <Dropdown
                    options={facilityOptions}
                    onSelect={(e) =>
                      setFacility(consumer.facilities.find((f) => f.facility_code == e))
                    }
                  />
                </TitleDiv>
                <InformationDiv>
                  <BreakDiv>
                    <Typography variant="pLarge" color="white">
                      CEP
                    </Typography>
                    <Typography variant="h5" color="white">
                      {facility.address.cep}
                    </Typography>
                  </BreakDiv>
                  <InformationBoxHide>
                    <BreakDiv>
                      <Typography variant="pLarge" color="white">
                        ENDEREÇO
                      </Typography>
                      <Typography variant="h5" color="white">
                        {facility.address.address}
                      </Typography>
                    </BreakDiv>
                  </InformationBoxHide>
                  <InformationBoxHide>
                    <BreakDiv>
                      <Typography variant="pLarge" color="white">
                        NÚMERO
                      </Typography>
                      <Typography variant="h5" color="white">
                        {facility.address.number}
                      </Typography>
                    </BreakDiv>
                    <BreakDiv>
                      <Typography variant="pLarge" color="white">
                        COMPLEMENTO
                      </Typography>
                      <Typography variant="h5" color="white">
                        {facility.address.complement}
                      </Typography>
                    </BreakDiv>
                  </InformationBoxHide>
                  <InformationBoxHide>
                    <BreakDiv>
                      <Typography variant="pLarge" color="white">
                        BAIRRO
                      </Typography>
                      <Typography variant="h5" color="white">
                        {facility.address.neighborhood}
                      </Typography>
                    </BreakDiv>
                  </InformationBoxHide>
                  <InformationBoxHide>
                    <BreakDiv>
                      <Typography variant="pLarge" color="white">
                        CIDADE
                      </Typography>
                      <Typography variant="h5" color="white">
                        {facility.address.city}
                      </Typography>
                    </BreakDiv>
                    <BreakDiv>
                      <Typography variant="pLarge" color="white">
                        ESTADO
                      </Typography>
                      <Typography variant="h5" color="white">
                        {facility.address.uf}
                      </Typography>
                    </BreakDiv>
                  </InformationBoxHide>
                </InformationDiv>
              </div>
            )}
          </>
        )}
        {!moreInfo ? (
          <CenterDiv>
            <ButtonDiv>
              <SimpleButton
                onClick={() => {
                  setMoreInfo(!moreInfo);
                }}
                backgroundColor="white"
                variant="simple"
                label="Dados Cadastrais +"
              />
            </ButtonDiv>
          </CenterDiv>
        ) : (
          <CenterDiv>
            <ButtonDiv>
              <SimpleButton
                onClick={() => {
                  setMoreInfo(!moreInfo);
                }}
                backgroundColor="white"
                variant="simple"
                label="Exibir menos --"
              />
            </ButtonDiv>
          </CenterDiv>
        )}
      </FirstDiv>
      {/* <SecondDiv>
        <Typography variant="h4" color="black">
          Financeiro
        </Typography>
        <InformationBox>
          <BreakDiv>
            <Typography variant="h6" color="black">
              Gasto mensal (médio)
            </Typography>
            <ValuesDiv>
              <IconDiv>
                <TbWallet size={20} />
              </IconDiv>
              <Typography variant="h3" color="black">
                R$
                <Typography variant="h3" color="white">
                  {" "}
                  {moneyFormatterWithoutCurrency(consumer.expenses_info.average)}
                </Typography>
              </Typography>
            </ValuesDiv>
          </BreakDiv>
          <BreakDiv>
            <Typography variant="h6" color="black">
              Gasto total
            </Typography>
            <ValuesDiv>
              <IconDiv>
                <TbWallet size={20} />
              </IconDiv>
              <Typography variant="h3" color="black">
                R$
                <Typography variant="h3" color="white">
                  {" "}
                  {moneyFormatterWithoutCurrency(consumer.expenses_info.total)}
                </Typography>
              </Typography>
            </ValuesDiv>
          </BreakDiv>
          <BreakDiv>
            <Typography variant="h6" color="black">
              Desconto
            </Typography>
            <ValuesDiv>
              <IconDiv>
                <FaMoneyBillWaveAlt size={20} />
              </IconDiv>
              <Typography variant="h3" color="black">
                <Typography variant="h3" color="white">
                  {(consumer.expenses_info.discount * 100).toFixed(2)}
                </Typography>{" "}
                %
              </Typography>
            </ValuesDiv>
          </BreakDiv>
        </InformationBox>
      </SecondDiv>
      <ThirdDiv>
        <Typography variant="h4" color="black">
          Consumo energético
        </Typography>
        <InformationBox>
          <BreakDiv>
            <Typography variant="h6" color="black">
              Consumo mensal (médio)
            </Typography>
            <ValuesDiv>
              <IconDiv>
                <AiOutlineThunderbolt size={22} />
              </IconDiv>
              <Typography variant="h3" color="white">
                {numberFormatter(consumer.consumption_info.average)}
                <Typography variant="h3" color="black">
                  {" "}
                  kWh
                </Typography>
              </Typography>
            </ValuesDiv>
          </BreakDiv>
          <BreakDiv>
            <Typography variant="h6" color="black">
              Consumo total
            </Typography>
            <ValuesDiv>
              <IconDiv>
                <AiOutlineThunderbolt size={22} />
              </IconDiv>
              <Typography variant="h3" color="white">
                {numberFormatter(consumer.consumption_info.total)}
                <Typography variant="h3" color="black">
                  {" "}
                  kWh
                </Typography>
              </Typography>
            </ValuesDiv>
          </BreakDiv>
        </InformationBox>
      </ThirdDiv> */}
      <BillsDiv>
        <ListSection>
          <Typography variant="h4" color="black">
            Contas ({billsInfo.length})
          </Typography>
          <FiltersContainer>
            <Dropdown
              options={yearsOptions}
              onSelect={(value) => {
                changeFilters({ year: value });
              }}
              defaultValue={String(today.getFullYear())}
            />
            <Dropdown
              options={statusOptions}
              onSelect={(value) => {
                changeFilters({ status: value });
              }}
            />
          </FiltersContainer>
        </ListSection>
        <CenterDiv>
          {billsInfo.length === 0 ? (
            <Typography variant="h6" color="black">
              O cliente ainda não possui contas.
            </Typography>
          ) : (
            <HeaderInfo>
              {billsInfo.map((bill: any, id: number) => (
                <BillsInfo
                  key={id}
                  month={monthFormat(bill.reference_month)}
                  value={moneyFormatter(bill.distributor_kwh_price)}
                  expensableTariff={moneyFormatter(bill.expensable_tariff_cents)}
                  valueNumber={bill.distributor_kwh_price}
                  kilowatts={numberFormatter(bill.compensated_kwh)}
                  total={moneyFormatter(bill.price)}
                  totalKwh={bill.total_kwh_price}
                  discountPercentage={bill.discount_percentage}
                  status={bill.payment_status === "paid" ? "Pago" : "Em aberto"}
                />
              ))}
            </HeaderInfo>
          )}
        </CenterDiv>
        {showMore() && (
          <ButtonContainer>
            <SimpleButton
              variant="simple"
              label="+ Carregar mais"
              onClick={() => {
                page += 1;
                router.reload({ data: { page: page } });
              }}
            />
          </ButtonContainer>
        )}
      </BillsDiv>
      <Page>
        <FaqCardComponent variant="questionSection" />
      </Page>
      <Footer />
    </div>
  );
};

export default InternalConsumer;

// export const getServerSideProps: GetServerSideProps = async (ctx) => {
//   const { id } = ctx.query;
//   const { currentUser } = parseCookies(ctx);
//   const user = await JSON.parse(currentUser);
//
//   const res = await Consumers.find(user.id, id, ctx);
//   const consumer = res.data;
//
//   return {
//     props: {
//       consumer: consumer,
//     },
//   };
// };
