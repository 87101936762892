import styled from "styled-components";

import theme from "@/constants/theme";

export const Container = styled.div`
  width: 100%;
  height: 94px;
  display: flex;

  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 24px;
  overflow: auto;

  div {
    display: flex;
    align-items: center;
  }

  .line {
    min-height: 62px;
    min-width: 1px;
    background-color: ${theme.colors.grey100};
  }

  button {
    width: 168px;
    height: 40px;
    border: none;
    border-radius: 56px;
    color: white;
    background-color: ${theme.colors.black};
    margin: 0px 24px 0px 24px;
  }
`;

export const Box = styled.div`
  width: 200px;
  height: 62px;
  display: flex;
  justify-content: center;
  align-items: center;

  .image {
    min-width: 30px;
    min-height: 30px;
    background-color: ${theme.colors.grey100};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    .icon-img {
      width: 20px;
      height: auto;
      font-weight: 700;
    }
  }

  .infos {
    width: 500px;
    padding-left: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const StatusBox = styled.div`
  width: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
`;
