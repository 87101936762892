import styled from "styled-components";

import { backgroundVariant, FaqComponentProps, iconBackgroundVariant } from "./types";

const backgroundColor = (variant: string) => {
  if (variant) {
    return backgroundVariant[variant];
  }
};

const iconBackground = (variant: string) => {
  if (variant) {
    return iconBackgroundVariant[variant];
  }
};

export const Emoti = styled.div<FaqComponentProps>`
  background-color: ${(props) => iconBackground(props.variant)};
  min-width: 6rem;
  min-height: 6rem;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 450px) {
    min-width: 70px;
    min-height: 70px;
  }
`;

export const FaqCard = styled.div<FaqComponentProps>`
  width: 100%;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) => backgroundColor(props.variant)};
  height: auto;
  padding: 20px 24px 20px 24px;
  cursor: pointer;

  @media (max-width: 450px) {
    padding: 16px 0px 16px 0px;
  }
`;

export const TextDiv = styled.div`
  width: 100%;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1em;
`;

export const FirstPart = styled.div`
  display: flex;
  align-items: center;
  gap: 2em;

  @media (max-width: 450px) {
    margin-left: 1em;
    gap: 1em;
  }
`;

export const FakeButton = styled.div`
  width: 15em;

  @media (max-width: 450px) {
    padding-right: 16px;
  }
`;
