import React from "react";
import { FiArrowLeft } from "react-icons/fi";

import SimpleButton from "@/components/Buttons/SimpleButton";
import DropDownCard from "@/components/Cards/DropDownCard";
import FaqCardComponent from "@/components/Cards/FaqComponent";
import Footer from "@/components/Footer";
import Typography from "@/components/Typography";

import { Container } from "./styles";
// import FaqService from '@/services/faq';
// import { GetServerSideProps } from 'next';
// import { parseCookies } from 'nookies';

export default function Faq({ faqInfos }: any) {
  return (
    <>
      <Container>
        <div className="back-button">
          <SimpleButton variant="back" label="Voltar" onClick={() => history.back()} />
        </div>
        <div className="back-button-mobile">
          <FiArrowLeft />
        </div>
        <div className="header">
          <Typography variant="h3" color="black">
            Perguntas frequentes
          </Typography>
          <div className="subtitle">
            <Typography variant="pLarge" color="black">
              Aqui você encontra todas as respostas para suas dúvidas sobre a Geedee e sua conta de
              luz.
            </Typography>
          </div>
        </div>
        {faqInfos == undefined || faqInfos.length == 0 ? (
          <div className="no-cards">
            <Typography variant="h6" color="black">
              Não há perguntas
            </Typography>
          </div>
        ) : (
          <div className="cards">
            {faqInfos.map((info: any, index: number) => {
              return (
                <div className="card" key={index}>
                  <DropDownCard label={info.question} text={info.answer} />
                </div>
              );
            })}
          </div>
        )}
        <div className="email">
          <div className="email-position">
            <FaqCardComponent variant="email" />
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
}

// export const getServerSideProps: GetServerSideProps = async ctx => {
//   const { currentUser } = parseCookies(ctx);
//   const { role } = await JSON.parse(currentUser);
//
//   const res = FaqService.getFaqs(role, ctx);
//
//   return {
//     props: {
//       faqInfos: (await res).data,
//     },
//   };
// };
