import styled from "styled-components";

export const Page = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 8rem 10rem;
  gap: 7rem;

  @media screen and (max-width: 768px) {
    padding: 2rem 1rem;
  }
`;

export const FirstDiv = styled.div`
  background-color: ${({ theme }) => theme.colors.primary500};
  height: 100%;
  padding: 4rem 8rem;
  margin: 0.5rem 0.5rem 0rem 0.5rem;
  border-radius: 20px 20px 0px 0px;

  @media screen and (max-width: 768px) {
    padding: 2rem 1rem;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  max-width: 12rem;
  padding-top: 1rem;
  margin: auto;
  align-items: center;
  justify-content: center;
`;

export const ButtonDiv = styled.div`
  max-width: 15rem;
`;

export const NameDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 3rem;
`;

export const AccountTimeDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleDiv = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

export const BreakDiv = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 425px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    gap: 1;
  }
  .green {
    background-color: ${({ theme }) => theme.colors.success200};
    color: black;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    padding: 0rem 1.2rem;
  }

  .red {
    background-color: ${({ theme }) => theme.colors.danger200};
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    padding: 0rem 1.2rem;
    color: white;
  }
`;

export const ValuesDiv = styled.div`
  display: flex;
  margin-top: 5px;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;

export const SecondDiv = styled.div`
  background-color: ${({ theme }) => theme.colors.primary400};
  height: 100%;
  border-radius: 20px 20px 0px 0px;
  padding: 6rem 8rem;
  margin: -1rem 0.5rem 0rem 0.5rem;

  @media screen and (max-width: 768px) {
    padding: 2rem 1rem;
  }
`;

export const InformationBox = styled.div`
  display: flex;
  width: 90%;
  justify-content: space-between;
  margin-top: 3rem;

  @media (max-width: 425px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    gap: 1rem;
    margin-bottom: 2rem;
  }
`;

export const InformationBoxHide = styled.div`
  display: flex;
  width: 90%;
  justify-content: space-between;
  margin-top: 3rem;
`;

export const InformationDiv = styled.div`
  margin-top: 2rem;
`;

export const ThirdDiv = styled.div`
  background-color: ${({ theme }) => theme.colors.primary300};
  margin: -1.5rem 0.5rem 0rem 0.5rem;
  border-radius: 20px 20px 0px 0px;
  padding: 4rem 8rem;

  @media screen and (max-width: 768px) {
    padding: 2rem 1rem;
  }
`;

export const BillsDiv = styled.div`
  background-color: ${({ theme }) => theme.colors.primary200};
  margin: -1.5rem 0.5rem 0rem 0.5rem;
  border-radius: 20px 20px 20px 20px;
  padding: 4rem 8rem;

  @media screen and (max-width: 768px) {
    padding: 2rem 1rem;
  }
`;

export const CenterDiv = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 4rem;
`;

export const HeaderInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
`;

export const IconDiv = styled.div`
  border-radius: 100%;
  width: 2rem;
  height: 2rem;
  background-color: ${({ theme }) => theme.colors.black};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const InfoIconDiv = styled.div`
  border-radius: 100%;
  width: 2rem;
  height: 2rem;
  background-color: ${({ theme }) => theme.colors.primary400};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Line = styled.div`
  height: 1px;
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 2rem;
  background-color: ${({ theme }) => theme.colors.grey100};
`;

export const DatesSecondDiv = styled.div`
  display: flex;
  gap: 1rem;
`;

export const ListSection = styled.div`
  min-width: 50%;
  display: flex;
  justify-content: space-between;
  padding-top: 64px;
`;

export const FiltersContainer = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
`;
