import { Dialog, Transition } from "@headlessui/react";
import { router } from "@inertiajs/react";
import axios from "axios";
import React, { Dispatch, Fragment, SetStateAction, useContext, useState } from "react";
import { BsXCircle } from "react-icons/bs";

import { AlertContext } from "@/contexts/Alert";

export default function ApproveExpenseModal({
  isOpen,
  setIsOpen,
  referenceMonth,
  consumer,
}: {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  referenceMonth: any;
  consumer: any;
}) {
  const { showAlert } = useContext<any>(AlertContext);
  const [notifyUser, setNotifyUser] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function updateExpense(approvedStatus: string) {
    axios
      .patch(`/manager/update_expense/${consumer.energy_expense_id}`, {
        approved_status: approvedStatus,
        notify_user: notifyUser,
      })
      .then(() => router.reload({ only: ["consumers"] }))
      .catch((e) =>
        showAlert({
          message: `erro: ${e}`,
          type: "error",
        }),
      );
    closeModal();
  }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-70"
            leave="ease-in duration-200"
            leaveFrom="opacity-70"
            leaveTo="opacity-0"
          >
            <div className="bg-opacity-/25 fixed inset-0 bg-black" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-xl overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="rounded-2xl bg-[#183F38] p-10 leading-6 text-white"
                  >
                    <div className="flex w-full justify-between">
                      <p className="text-4xl font-bold">Aprovar conta</p>
                      <div className="cursor-pointer" onClick={closeModal}>
                        <BsXCircle />
                      </div>
                    </div>
                    <p className="text-lg font-normal text-[#748C88]">{consumer.name}</p>
                  </Dialog.Title>
                  <div className="p-10">
                    <p className="mb-4 text-center text-lg font-bold text-black">
                      Deseja confirmar os dados da conta de {referenceMonth.month} de{" "}
                      {referenceMonth.year} do cliente {consumer.name}?
                    </p>
                    <div className="flex w-full justify-center gap-4 " />
                    <input
                      type="checkbox"
                      checked={notifyUser}
                      onChange={() => setNotifyUser(!notifyUser)}
                    />{" "}
                    Notificar usuário
                  </div>

                  <div className="flex justify-end gap-2 border-t p-4">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-red-700 px-4 py-2 text-sm font-medium text-white focus:outline-none"
                      onClick={closeModal}
                    >
                      Fechar
                    </button>
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-green-700 px-4 py-2 text-sm font-medium text-white focus:outline-none"
                      onClick={() => updateExpense("approved")}
                    >
                      Aprovar
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
