// @ts-ignore
import baseStyled, { StyledInterface } from "styled-components";

import { colorType } from "./types";

interface themeProps {
  colors: { [key in colorType]: string };
}

const theme: themeProps = {
  colors: {
    black: "#010809",
    white: "#ffffff",
    grey100: "#EBF2F5",
    grey200: "#D3D8DB",
    grey300: "#B2BCC2",
    grey400: "#9BA3A8",
    grey500: "#818A8F",
    grey600: "#586166",
    grey700: "#40474D",
    grey800: "#2A2F33",
    grey900: "#1D2124",
    grey950: "#0E1012",
    primary100: "#748C88",
    primary200: "#5D7974",
    primary300: "#466560",
    primary400: "#2F524C",
    primary500: "#183F38",
    secondary100: "#C8FDD3",
    secondary200: "#9FFCB2",
    secondary300: "#76FA91",
    secondary400: "#5EC874",
    secondary500: "#479657",
    tertiary100: "#D2F3F4",
    tertiary200: "#B8EBEC",
    tertiary300: "#90BCBD",
    success100: "#C5F558",
    success200: "#A2D729",
    success300: "#7AA31D",
    warning100: "#FFCE70",
    warning200: "#FFBB38",
    warning300: "#DB9C23",
    danger100: "#ED7D6D",
    danger200: "#E95D49",
    danger300: "#BA4A3A",
  },
};

export default theme;
export type Theme = typeof theme;
export const styled = baseStyled as StyledInterface;
