import styled from "styled-components";

import { AvatarProps } from "./type";

export const Container = styled.div<AvatarProps>`
  border-radius: 72px;
  width: 40px;
  height: 40px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.primary300};
  color: white;
  font-weight: bold;
`;
