import styled from "styled-components";

import Typography from "@/components/Typography";

export const Container = styled.div<{ type: "success" | "error" }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 24px 8px 8px;
  gap: 32px;

  width: 100%;
  height: 56px;

  /* Color/System/Success 200 (B) */

  background: ${(props) =>
    props.type === "error" ? props.theme.colors.warning200 : props.theme.colors.success200};
  border-radius: 64px;

  /* Inside auto layout */

  @media (max-width: 450px) {
    height: 100%;
    border-radius: 24px;
  }
`;

export const IconBackground = styled.div<{ type: "success" | "error" }>`
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  justify-content: center;
  align-items: center;
  margin: auto;
  /* Warning/Success 100 */
  background: ${(props) =>
    props.type === "error" ? props.theme.colors.warning100 : props.theme.colors.success100};
`;

export const SnackBarText = styled(Typography)`
  flex: 1;
  color: #010809;
`;

export const CloseContainer = styled.div`
  cursor: pointer;
`;

export const Warning = styled.div`
  width: 100%;
  z-index: 1000;
  position: fixed;
  bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 2rem;
  padding-right: 2rem;
`;
