import styled from "styled-components";

import theme from "@/constants/theme";
import { colorType } from "@/constants/types";

const backgroundColor = (color: colorType) => {
  return theme.colors[color];
};

export const Container = styled.div<{ background: colorType }>`
  width: 100%;
  min-height: 100%;
  background-color: ${(props) => backgroundColor(props.background)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32.5px 48px 32.5px 48px;
  border-radius: 88px;
  cursor: pointer;

  .button {
    width: auto;
    padding-left: 48px;
  }

  @media (max-width: 450px) {
    height: auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 192px;
    border-radius: 32px;
    padding: 50px 16px 20px 16px;

    .button {
      width: 100%;
      height: auto;
      padding: 20px 0px 20px 0px;
    }
  }

  @media (max-width: 350px) {
    height: 230px;
  }
`;
