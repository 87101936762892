import { Menu } from "@headlessui/react";
import { Link } from "@inertiajs/react";
import styled from "styled-components";

import theme from "@/constants/theme";

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;
export const HeaderCenter = styled.div`
  width: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: start;
  padding: 24px 24px 8px 24px;
  z-index: 2;
`;
export const Header = styled.div<{ $background: boolean }>`
  background-color: ${theme.colors.white};
  width: 100%;
  height: 72px;
  box-shadow: 0px 0px 16px rgba(43, 137, 116, 0.24);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 64px;

  @media (max-width: 450px) {
    background-color: ${(props) => (props.$background == true ? "white" : "inherit")};
    box-shadow: none;
  }
`;

export const Pages = styled.div`
  display: flex;
  gap: 2.5em;

  .active {
    background-color: ${theme.colors.primary500};
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 14px;
    border-radius: 20px;
  }
`;

export const User = styled.div`
  display: flex;
  gap: 1em;
  margin-right: 1em;
  justify-content: center;
  align-items: center;

  @media (max-width: 450px) {
    display: none;
  }
`;

export const LogOutDiv = styled.div`
  width: auto;
  height: 100%;
  right: 1px;
  display: flex;
  align-items: center;
`;

export const LogOutButton = styled(Menu.Button)`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: none;
  background-color: ${({ theme }) => theme.colors.grey100};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 14px;
  left: -30px;
`;

export const Align = styled.div`
  width: calc(100% - 100px);
  display: flex;
  justify-content: space-between;

  @media (max-width: 450px) {
    display: none;
  }
`;

export const SideBar = styled.div<{ $active: boolean }>`
  display: none;

  @media (max-width: 450px) {
    width: 100%;
    height: 100%;
    padding: 10px;
    position: relative;
    display: flex;

    .icon-circle-white {
      min-width: 56px;
      min-height: 56px;
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
    }

    .icon-circle {
      width: 40px;
      height: 40px;
      background-color: black;
      padding: 5px;
      border-radius: 50%;
      font-size: 24px;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .deactivate-sidebar {
      background-color: ${theme.colors.grey100};
      opacity: 50%;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: ${(props) => (props.$active ? "none" : "")};
    }

    .functions {
      min-width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .logo {
        width: auto;
        padding: 16px;
        background-color: orange;
        border-radius: 16px;
      }
    }
  }
`;

export const Bar = styled.div<{ $active: boolean }>`
  width: 70%;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  background-color: ${theme.colors.primary500};
  opacity: 100%;
  display: flex;
  flex-direction: column;
  z-index: 30;
  transform: ${(props) => (props.$active ? "translateX(500%)" : "translateX(0%)")};
  transition: all 0.5s ease-in-out;
  font-weight: 700;
  overflow: hidden;

  .link-page {
    padding: 20px 0px 0px 16px;
  }

  .sidebar-circles {
    position: absolute;
    bottom: -100px;
    left: -20px;
  }
`;

export const LogOutButtonDiv = styled.div`
  width: 10rem;
  height: auto;
  border-radius: 10px;
  position: absolute;
  top: 1.8rem;
  right: 2rem;
  background-color: ${theme.colors.white};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  border: 1px solid ${theme.colors.grey100};
  gap: 0.5rem;
  cursor: pointer;
  padding-left: 0.5rem;
`;
