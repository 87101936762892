import React from "react";

import Logo from "@/components/Logo";

import Typography from "../Typography";
import { FooterDiv } from "./styles";

const Footer = () => {
  return (
    <FooterDiv>
      <Logo marginClass="ml-0" />
      <Typography variant="pLarge" color="grey500">
        © Geedee. Todos os direitos reservados.
      </Typography>
    </FooterDiv>
  );
};

export default Footer;
