import styled from "styled-components";

export const ProgressBarContainer = styled.div`
  width: 75%;
  height: 20px;
  background-color: #ddd;
  border-radius: 5px;
`;

export const ProgressBar = styled.div`
  height: 100%;
  background-color: ${(props) => props.color};
  border-radius: inherit;
  transition: width 0.3s ease-in-out;
`;
