import { Link, router, usePage } from "@inertiajs/react";
import React, { useState } from "react";

import SimpleButton from "@/components/Buttons/SimpleButton";
import { BigNumber } from "@/components/Cards/BigNumber";
import BillsCard from "@/components/Cards/Conta";
import EconomyCard from "@/components/Cards/EconomyCard";
import FaqCardComponent from "@/components/Cards/FaqComponent";
import Dropdown from "@/components/Dropdown";
import Layout from "@/components/Layout";
import Typography from "@/components/Typography";
import {
  ButtonContainer,
  FiltersContainer,
  HeaderInfo,
  ListSection,
  PageContainer,
} from "@/constants/listIndexStyle";
import { bigNumberFormatter } from "@/utils/bigNumberFormatter";
import { monthFormat } from "@/utils/dateFormater";
import moneyFormatter from "@/utils/moneyFormat";
import numberFormatter from "@/utils/numberFormatter";

const statusOptions: string[] = ["Todas", "Em aberto", "Pago"];

const BillsPage = ({ billInfo }: any) => {
  let { page }: any = usePage().props;
  const { currentUser }: any = usePage().props;
  const today = new Date();
  const { bills, totalPages }: any = usePage().props;
  const [filters, setFilters] = useState({
    year: String(today.getFullYear()),
    status: "",
  });
  const yearsOptions = () => {
    const currentYear = today.getFullYear();
    let creationYear = new Date(currentUser.created_at).getFullYear();
    const years: string[] = [];

    while (creationYear <= currentYear) {
      years.push(String(creationYear));
      creationYear++;
    }

    return years;
  };

  const statusValues = { Todas: null, "Em aberto": "not_paid", Pago: "paid" };

  const changeFilters = ({ year = filters.year, status = filters.status }) => {
    page = 1;
    setFilters({ year, status });
    router.reload({
      data: {
        page: page,
        year: year,
        payment_status: statusValues[status] !== undefined ? statusValues[status] : status,
      },
    });
  };
  const showMore = () => page != totalPages && bills.length != 0;

  return (
    <Layout>
      <PageContainer>
        <Typography variant="h3">Contas</Typography>
        <HeaderInfo>
          <div className="grid w-full grid-cols-1 justify-between gap-4 pt-5 md:grid-cols-4">
            <BigNumber
              className="col"
              infoTitle="Gastos Totais"
              info={moneyFormatter(billInfo.total_price)}
            />
            <BigNumber
              className="col"
              infoTitle="Economia Total"
              info={moneyFormatter(billInfo.total_discount)}
            />
            <BigNumber
              className="col"
              infoTitle="Kilowatts Totais"
              info={bigNumberFormatter(billInfo.total_kwh) + " kWh"}
            />
            <BigNumber
              className="col"
              infoTitle="CO2 Compensados"
              info={bigNumberFormatter(billInfo.compensed_co2) + " kg"}
            />
          </div>
          <EconomyCard monthlyInfos={billInfo.discount_info} />
        </HeaderInfo>
        <ListSection>
          <Typography variant="h4">Suas contas</Typography>
          <FiltersContainer>
            <Dropdown
              options={yearsOptions()}
              onSelect={(value) => {
                changeFilters({ year: value });
              }}
              defaultValue={String(today.getFullYear())}
            />
            <Dropdown
              options={statusOptions}
              onSelect={(value) => {
                changeFilters({ status: value });
              }}
            />
          </FiltersContainer>
        </ListSection>
        <HeaderInfo>
          {bills.length !== 0 ? (
            <>
              {bills.map((bill, id) => (
                <Link className="w-full" key={id} href={`/consumer/internal_bills/${bill.id}`}>
                  <BillsCard
                    key={id}
                    month={monthFormat(bill.reference_month)}
                    value={moneyFormatter(bill.price)}
                    kilowatts={numberFormatter(bill.kwh)}
                    status={bill.payment_status === "paid" ? "Pago" : "Em aberto"}
                    buttonLabel="Ver conta"
                  />
                </Link>
              ))}
            </>
          ) : (
            <Typography variant="h6">Não houve resultado para sua filtragem</Typography>
          )}
        </HeaderInfo>

        {showMore() && (
          <ButtonContainer>
            <SimpleButton
              variant="simple"
              label="+ Carregar mais"
              onClick={() => {
                page += 1;
                router.reload({ data: { page: page } });
              }}
            />
          </ButtonContainer>
        )}
        <div className="pt-36">
          <FaqCardComponent variant="questionSection" />
        </div>
      </PageContainer>
    </Layout>
  );
};

export default BillsPage;
