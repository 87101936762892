import React from "react";

import { StyledLink } from "@/components/NavBar/styles";
import Typography from "@/components/Typography";

interface NavbarLinkProps {
  pathTo: string;
  actualPath: string | null;
  name: string;
  icon: React.FC<{ color: string }>;
}

const NavbarLink = ({ pathTo, actualPath, name, icon }: NavbarLinkProps) => {
  const activeClass = () => (isActualPath ? "active" : "");

  const activeColor = () => (isActualPath ? "white" : "black");

  const isActualPath = (actualPath ?? "").includes(pathTo);
  return (
    <div className={activeClass()}>
      <StyledLink href={pathTo}>
        {isActualPath && React.createElement(icon, { color: "white" })}
        <Typography variant="btnLarge" color={activeColor()} cursorPointer={true}>
          {name}
        </Typography>
      </StyledLink>
    </div>
  );
};

export default NavbarLink;
