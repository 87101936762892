import styled from "styled-components";

export const Page = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 8rem 10rem;
  gap: 7rem;

  @media screen and (max-width: 768px) {
    padding: 2rem 1rem;
  }
`;

export const BillsCard = styled.div`
  background-color: ${({ theme }) => theme.colors.primary500};
  height: 100%;
  padding: 4rem 8rem;
  margin: 0.5rem 0.5rem 0rem 0.5rem;
  border-radius: 20px 20px 0px 0px;

  @media screen and (max-width: 768px) {
    padding: 2rem 1rem;
  }
`;

export const ButtonDiv = styled.div`
  max-width: 15rem;
`;

export const SecondButtonDiv = styled.div`
  max-width: 15rem;
  margin-top: 40px;
`;

export const BreakDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DatesDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
`;

export const Line = styled.div`
  min-height: 62px;
  min-width: 1px;
  background-color: ${({ theme }) => theme.colors.primary200};
`;

export const BillDate = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 3rem;
`;

export const Value = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 8rem;
`;

export const ValuesDiv = styled.div`
  display: flex;
  padding-right: 8rem;
`;

export const ThirdInfoDiv = styled.div`
  background-color: ${({ theme }) => theme.colors.primary400};
  height: 100%;
  border-radius: 20px 20px 0px 0px;
  padding: 4rem 8rem;
  margin: 0.5rem 0.5rem 0rem 0.5rem;
  display: flex;
  justify-content: space-around;

  @media screen and (max-width: 768px) {
    padding: 2rem 1rem;
  }
`;

export const EconomyInfoDiv = styled.div`
  display: flex;
  gap: 1rem;
  padding-bottom: 1rem;
  width: 80%;
`;

export const DatesSecondDiv = styled.div`
  display: flex;
  gap: 1rem;
`;

export const AccountTimeDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

export const EconomyFirstCard = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 5rem;
  border-radius: 20px 20px 0px 0px;
  padding: 0.5rem;
  background-color: ${({ theme }) => theme.colors.primary300};
`;

export const EconomySecondCard = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 5rem;
  border-radius: 0px 0px 20px 20px;
  padding: 0.5rem;
  margin-top: 0.1rem;
  background-color: ${({ theme }) => theme.colors.primary300};
`;

export const EconomyCard = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 5rem;
  border-radius: 20px;
  padding: 0.5rem;
  margin-top: 0.5rem;
  background-color: ${({ theme }) => theme.colors.primary500};
`;

export const EconomyDiv = styled.div`
  width: 80%;
`;

export const InformationBox = styled.div`
  background-color: ${({ theme }) => theme.colors.primary500};
  display: flex;
  flex-direction: column;
  align-self: end;
  padding: 2rem;
  margin: 2rem 0rem;
  border-radius: 2rem;
  gap: 1rem;
  width: 80%;
`;

export const ThourthInfoDiv = styled.div`
  background-color: ${({ theme }) => theme.colors.primary300};
  margin: -1.5rem 0.5rem 0rem 0.5rem;
  border-radius: 20px 20px 0px 0px;
  padding: 4rem 8rem;

  @media screen and (max-width: 768px) {
    padding: 2rem 1rem;
  }
`;

export const InformationLinedDiv = styled.div`
  border-top: 0.1px solid ${({ theme }) => theme.colors.grey300};
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 1rem;
  justify-content: space-between;
  padding-top: 1rem;
`;
