import React from "react";

import { AvatarProps } from "@/components/Avatar/type";

import Typography from "../Typography";
import { Container } from "./styles";

const Avatar = ({ user }: AvatarProps) => {
  return (
    <Container>
      <Typography variant="h6" color="white">
        {user?.charAt(0).toLocaleUpperCase()}
      </Typography>
    </Container>
  );
};

export default Avatar;
