import styled from "styled-components";

import theme from "@/constants/theme";

export const Container = styled.div<{ $displayIcon: boolean }>`
  width: 100%;
  height: 106px;
  background-color: ${theme.colors.tertiary100};
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  .box {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: ${(props) => (!props.$displayIcon ? "26px" : "10px")};

    .infos {
      height: auto;
      display: flex;
      flex-direction: column;
      text-transform: uppercase;
      font-weight: 700;
      padding-left: ${(props) => (!props.$displayIcon ? "26px" : "0px")};
    }

    .icon {
      width: 32px;
      height: 32px;
      background-color: ${theme.colors.tertiary300};
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      color: ${theme.colors.black};
      font-size: 18px;
    }
  }
`;
