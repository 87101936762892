import styled from "styled-components";

import theme from "@/constants/theme";

import solarPanel from "../../../public/solarPanel.jpg";

export const Container = styled.div`
  background-color: ${theme.colors.primary100};
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  padding: 8px;
`;

export const Infos = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${theme.colors.white};
  padding-top: 48px;
  margin-right: 8px;
  border-radius: 32px;
`;
export const Img = styled.div`
  height: 100%;
  border-radius: 30px;

  div {
    width: 100%;
    height: 100%;
    background-image: url(${solarPanel});
    background-repeat: no-repeat;
    background-position: -500px -250px;
    border-radius: 32px;
  }
`;
export const LogoContainer = styled.div`
  width: 80%;
  height: auto;
  display: flex;
  justify-content: center;

  div {
    width: 100px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
  }
`;

export const Access = styled.div`
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
export const Form = styled.form`
  height: auto;

  .header {
    display: flex;
    flex-direction: column;
    padding-bottom: 88px;
  }

  .forms-input {
    width: 100%;
    padding-bottom: 20px;

    .input {
      width: 100%;
      padding-bottom: 20px;
    }
  }

  .button {
    width: 65%;
  }
`;

export const PasswordInfos = styled.div`
  height: auto;
  display: flex;
  align-items: center;
  padding: 34px 0px 54px 0px;

  .container {
    width: 20px;
    margin-right: 10px;
  }

  .checkbox {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .container input {
    display: none;
  }

  span {
    width: 50%;
  }

  .container span {
    width: 20px;
    height: 20px;
    border-radius: 2px;
    display: block;
    background-color: #fff;
    border: 1px solid #ddd;
    color: white;
    cursor: pointer;
  }

  .container input:checked + span {
    background-color: ${theme.colors.primary300};
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    cursor: pointer;
  }

  .remember-password {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    font-weight: 700;
    text-align: right;

    span {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      cursor: pointer;
    }
  }
`;

export const SignUp = styled.div`
  width: 100%;
  display: flex;
  padding-bottom: 61px;
  cursor: pointer;

  .signup-page {
    width: 40%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 10px;
    font-weight: 700;
  }

  .icon {
    height: 100%;
    width: 30px;
    color: ${theme.colors.black};
    padding-left: 10px;
  }
`;
