import theme from "@/constants/theme";

export interface FaqComponentProps {
  variant: "questionSection" | "email" | "dataSection";
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  buttonLabel?: string;
}

export const backgroundVariant: { [id: string]: string } = {
  email: theme.colors.primary500,
  dataSection: theme.colors.white,
  questionSection: theme.colors.white,
};

export const iconBackgroundVariant: { [id: string]: string } = {
  email: theme.colors.primary400,
  dataSection: theme.colors.primary500,
  questionSection: theme.colors.primary500,
};
