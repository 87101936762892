import styled from "styled-components";

import theme from "@/constants/theme";

export const Container = styled.div`
  width: 100%;
  height: auto;

  .drop-down-button {
    width: 100%;
    height: 88px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 24px 0px 24px;
    border-radius: 24px;
    position: relative;
    z-index: 1;
    background-color: white;
    border: none;
    cursor: pointer;
    overflow: auto;

    .icon-circle {
      min-width: 40px;
      min-height: 40px;
      border-radius: 50%;
      background-color: ${theme.colors.black};
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      font-size: 20px;
    }
  }

  .drop-down-text {
    width: 100%;
    height: auto;
    position: relative;
    margin-top: -44px;
    padding: 68px 24px 24px 24px;
    background-color: ${theme.colors.primary500};
    z-index: 0;
    border-radius: 24px;
    text-align: left;
  }

  @media (max-width: 450px) {
    .drop-down-button {
      width: 100%;
      height: calc(auto + 20px);
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 24px;
      position: relative;
      z-index: 1;
      border: none;
      text-align: start;
      background-color: ${theme.colors.white};

      .icon-circle {
        min-width: 24px;
        min-height: 24px;
        border-radius: 50%;
        background-color: ${theme.colors.black};
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-size: 20px;
      }
    }
  }
`;

export const DisplayDiv = styled.div`
  display: flex;
  justify-content: center;

  .line {
    min-height: 62px;
    min-width: 1px;
    background-color: ${theme.colors.grey100};
  }
`;

export const Adjust = styled.div`
  display: flex;
  align-items: center;

  .line {
    min-height: 62px;
    min-width: 1px;
    background-color: ${theme.colors.grey100};
    margin-right: 2rem;
  }
`;

export const ThourthInfoDiv = styled.div`
  background-color: ${({ theme }) => theme.colors.primary300};
  margin: -1.5rem 0.5rem 0rem 0.5rem;
  border-radius: 20px 20px 0px 0px;
  padding: 4rem 8rem;

  @media screen and (max-width: 768px) {
    padding: 2rem 1rem;
  }
`;

export const InformationLinedDiv = styled.div`
  border-top: 0.1px solid ${({ theme }) => theme.colors.grey300};
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 1rem;
  justify-content: space-between;
  padding-top: 1rem;
`;

export const LastPart = styled.div`
  display: flex;
  width: 40%;

  .line {
    min-height: 62px;
    min-width: 1px;
    background-color: ${theme.colors.grey100};
  }
`;
