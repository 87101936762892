import styled from "styled-components";

import theme from "@/constants/theme";

export const SearchForm = styled.form`
  position: relative;

  input {
    width: 100%;
    height: 50px;
    border-radius: 24px;
    box-sizing: border-box;
    margin-bottom: 10px;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    text-align: left;
    padding-left: 20px;
    font-family: "Inter", sans-serif;
    color: ${theme.colors.black};
  }

  .search-icon {
    height: 50px;
    position: absolute;
    display: flex;
    align-items: center;
    top: 0px;
    right: 16px;
    font-size: 24px;
  }
`;

export const PowerPlantCardContainer = styled.div`
  width: 100%;
  height: 94px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 24px;

  padding: 24px;
  margin-bottom: 8px;
  overflow: auto;

  button {
    padding: 1.75rem;
  }

  .numbers {
    width: auto;
    display: flex;
    align-items: center;

    .line {
      min-height: 62px;
      min-width: 1px;
      background-color: ${theme.colors.grey100};
      margin: 0px 16px 0px 120px;
    }
  }
`;
