import { router } from "@inertiajs/react";
import React from "react";
import { FiArrowRight } from "react-icons/fi";

import BillsToPay from "@/components/Cards/BillsToPay";
import FaqCardComponent from "@/components/Cards/FaqComponent";
import { CardValue } from "@/components/Cards/Value";
import CirclesBigComponent from "@/components/Circles/CirclesBigComponent";
import Layout from "@/components/Layout";
import SnackBar from "@/components/SnackBar";
import Typography from "@/components/Typography";
import { dateFormat, simpleMonthYearFormat } from "@/utils/dateFormater";
import { moneyFormatterWithoutCurrency } from "@/utils/moneyFormat";
import numberFormatter from "@/utils/numberFormatter";

import {
  Container,
  CurrentCountPosition,
  FaqCard,
  FirstTimePosition,
  Infos,
  Warning,
} from "./styles";

const Homepage = ({ current, previous, distribution_status }: any) => {
  const checkVariant = (): string => {
    if (distribution_status == "stopped") {
      return "paused-distribution";
    } else if (current.payment_status == "late") {
      return "late-bills";
    } else if (current.payment_status == "paid") {
      return "paid-bills";
    } else if (current.payment_status == "not_paid") {
      return "open-account";
    } else {
      return "first-time";
    }
  };

  const variant = checkVariant();

  return (
    <Layout>
      <Container>
        <Infos variant={variant}>
          <div className="title-position">
            <Typography variant="h3" color="white">
              Cheque sua conta de luz
            </Typography>
          </div>
          {variant == "paused-distribution" && (
            <Warning>
              <div className="warning-center">
                <SnackBar
                  type="error"
                  isOpen={variant === "paused-distribution"}
                  message="A distribuição foi pausada temporariamente. Regularize a situação para retomar o serviço."
                />
              </div>
            </Warning>
          )}
          {current === false ? (
            <FirstTimePosition>
              <Typography variant="h6" color="white">
                Você ainda não possui contas na Geedee :)
              </Typography>
              {current && (
                <div className="next-bill">
                  <Typography variant="h6" color="black">
                    Próxima fatura . {dateFormat(current?.due_date)}
                  </Typography>
                </div>
              )}
              <div className="circles-background">
                <CirclesBigComponent variant="grey" />
              </div>
            </FirstTimePosition>
          ) : (
            variant != "first-time" && (
              <CurrentCountPosition variant={variant}>
                <div className="values">
                  <CardValue
                    variant="money"
                    value={moneyFormatterWithoutCurrency(current?.price)}
                    lastMonthValue={
                      previous !== false && moneyFormatterWithoutCurrency(previous.price)
                    }
                    month={simpleMonthYearFormat(current.reference_month)}
                  />
                  <CardValue
                    variant="energy"
                    value={numberFormatter(current?.compensated_kwh)}
                    lastMonthValue={previous !== false && previous.compensated_kwh}
                  />
                </div>
                <div className="card-position">
                  <BillsToPay
                    variant={
                      current?.payment_status == "not_paid"
                        ? "pay"
                        : current?.payment_status == "paid"
                          ? "in-day"
                          : "late"
                    }
                    date={current?.due_date}
                    onClick={() => router.get(`/consumer/internal_bills/${current.id}`)}
                  />
                </div>
                {variant == "open-account" && (
                  <div className="check-details">
                    <Typography variant="h4" color="white">
                      Cheque os detalhes da conta do mês.
                    </Typography>
                    <div className="next-page">
                      <div onClick={() => router.get(`/consumer/internal_bills/${current?.id}`)}>
                        <div className="check-details-text-link">
                          <Typography variant="link" color="white">
                            Checar detalhes
                          </Typography>
                        </div>
                      </div>
                      <FiArrowRight />
                    </div>
                  </div>
                )}
              </CurrentCountPosition>
            )
          )}
        </Infos>
        <FaqCard>
          <FaqCardComponent variant="email" />
        </FaqCard>
      </Container>
    </Layout>
  );
};

export default Homepage;
