import React from "react";

import Typography from "@/components/Typography";

import gradient from "../../../../public/gradient.png";
import { Container } from "./styles";
import { BigNumberProps } from "./types";

export const BigNumber = ({
  infoTitle,
  icon,
  info,
  ...rest
}: BigNumberProps & React.HTMLAttributes<HTMLDivElement>) => {
  let displayIcon = false;

  if (icon == undefined) {
    displayIcon = true;
  }

  return (
    <Container $displayIcon={displayIcon} {...rest}>
      <div className="box">
        {icon ? <div className="icon">{icon}</div> : <></>}
        <div className="infos">
          <Typography color="black" variant="overline">
            {infoTitle.toLocaleUpperCase()}
          </Typography>
          <Typography color="black" variant="h6">
            {info}
          </Typography>
        </div>
      </div>
      <img src={gradient} alt="gradient" />
    </Container>
  );
};
