import { Popover } from "@headlessui/react";
import { router } from "@inertiajs/react";
import React from "react";
import { BsExclamation } from "react-icons/bs";
import { IoFlashOutline } from "react-icons/io5";
import { TbSunrise } from "react-icons/tb";

import SimpleButton from "@/components/Buttons/SimpleButton";
import ContaInfos from "@/components/Cards/ContaInfos";
import Typography from "@/components/Typography";
import { PowerPlantCardContainer } from "@/pages/manager/power-plants/style";
import { PowerPlantCardProps } from "@/pages/manager/power-plants/types";

const PowerPlantCard: React.FC<PowerPlantCardProps> = ({
  id,
  name,
  energy,
  onClick,
  percentageLetters,
}) => {
  return (
    <PowerPlantCardContainer>
      <div className="numbers">
        <ContaInfos title="NOME" info={name} image={<TbSunrise />} />
        <div className="line" />
        <ContaInfos title="CAPACIDADE INSTALADA" info={energy} image={<IoFlashOutline />} />
      </div>
      <div className="mr-0 flex items-center gap-2 pr-0">
        {!percentageLetters && (
          <div className="flex items-center">
            <Popover>
              <Popover.Button>
                <BsExclamation className="rounded-full bg-yellow-400" size={24} />
              </Popover.Button>

              <Popover.Panel className="absolute right-6 z-50 w-64 rounded bg-gray-900 p-5 lg:w-96">
                <Typography variant="pLarge" color="white">
                  Esta usina ainda não possui uma carta de rateio
                </Typography>
              </Popover.Panel>
            </Popover>
          </div>
        )}
        <SimpleButton
          variant="simple"
          label="Rateio"
          onClick={() => router.get(`/manager/power-plants/${id}/percentage-letters`)}
        />

        <SimpleButton variant="simple" label="Detalhes" onClick={onClick} />
      </div>
    </PowerPlantCardContainer>
  );
};

export default PowerPlantCard;
