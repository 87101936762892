import { usePage } from "@inertiajs/react";
import React, { PropsWithChildren } from "react";

import Footer from "../Footer";
import NavBar from "../NavBar";
import { Page } from "./styles";

const Layout = ({ children }: PropsWithChildren) => {
  const { currentUser }: any = usePage().props;

  return (
    <Page className="h-full">
      <div className="header-position">
        <NavBar user={currentUser?.name} userVariant={currentUser?.role} />
      </div>
      {children}
      <Footer />
    </Page>
  );
};

export default Layout;
