import React from "react";

import Typography from "@/components/Typography";

import { Container } from "./styles";
import { StepCardProps } from "./types";

const StepCard = ({ title, step, total, text }: StepCardProps) => {
  return (
    <Container>
      <div className="header">
        <Typography variant="h5" color="white">
          {title}
        </Typography>
        <div className="steps">
          <div className="steps-position">
            <Typography variant="overline" color="white">
              Etapa {step} de {total}
            </Typography>
          </div>
        </div>
      </div>
      <Typography variant="h5" color="primary100">
        {text}
      </Typography>
    </Container>
  );
};

export default StepCard;
