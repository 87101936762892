export const completMonth = [
  "Janeiro",
  "Fevereiro",
  "Março",
  "Abril",
  "Maio",
  "Junho",
  "Julho",
  "Agosto",
  "Setembro",
  "Outubro",
  "Novembro",
  "Dezembro",
];

const simpleMonth = [
  "Jan",
  "Fev",
  "Mar",
  "Abr",
  "Mai",
  "Jun",
  "Jul",
  "Ago",
  "Set",
  "Out",
  "Nov",
  "Dez",
];

export const dateFormat = (date: string) => {
  const infos = date.split("-");
  const year = infos[0];
  const month = new Date(infos[1]);
  const day = infos[2];
  const dataFormatada = day + " " + simpleMonth[month.getMonth()] + " " + year;

  return dataFormatada;
};

export const monthFormat = (date: string) => {
  const infos = date.split("-");
  const month = new Date(infos[1]);
  const dataFormatada = completMonth[month.getMonth()];

  return dataFormatada;
};

export const monthYearFormat = (date: string) => {
  const infos = date.split("-");
  const year = infos[0];
  const data = new Date(infos[1]);
  const dataFormatada = completMonth[data.getMonth()] + " " + "de" + " " + year;

  return dataFormatada;
};

export const simpleMonthYearFormat = (date: string) => {
  const infos = date.split("-");
  const year = infos[0];
  const data = new Date(infos[1]);
  const dataFormatada = simpleMonth[data.getMonth()] + " " + year;

  return dataFormatada;
};

export const getMonth = () => {
  const objDate = new Date();
  const locale = "pt-br",
    month = objDate.toLocaleString(locale, { month: "long" });

  return month;
};
