import { Disclosure, Popover, Transition } from "@headlessui/react";
import React from "react";
import { BiWallet } from "react-icons/bi";
import { BsCalendar2Week, BsQuestion } from "react-icons/bs";
import { FiMinus, FiPlus } from "react-icons/fi";
import { IoFlashOutline } from "react-icons/io5";

import Typography from "@/components/Typography";

import ContaInfos from "../ContaInfos";
import { Adjust, Container, DisplayDiv, InformationLinedDiv } from "./styles";
import { BillsInfoProps } from "./types";

const DropDownCard = ({
  month,
  value,
  expensableTariff,
  kilowatts,
  status,
  total,
  discountPercentage,
}: BillsInfoProps) => {
  const porcentageGeedee = () => discountPercentage * 100;
  return (
    <Container>
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className="drop-down-button" data-testid={`icon-button-${month}`}>
              <DisplayDiv>
                <ContaInfos info={month} title="MÊS" image={<BsCalendar2Week />} />
                <div className="line" />
                <ContaInfos info={total} title="VALOR" image={<BiWallet />} />
                <div className="line" />
                <ContaInfos info={kilowatts} title="KILOWATTS" image={<IoFlashOutline />} />
              </DisplayDiv>
              <Adjust>
                <ContaInfos info={status} title="Situação" />
                <div className="line" />
                <div className="icon-circle">{!open ? <FiPlus /> : <FiMinus />}</div>
              </Adjust>
            </Disclosure.Button>
            <Transition
              enter="transform transition duration-500 ease-custom"
              enterFrom="-translate-y-1/2 scale-y-0 opacity-0"
              enterTo="translate-y-0 scale-y-100 opacity-100"
              leave="transform transition duration-500 ease-custom"
              leaveFrom="translate-y-0 scale-y-100 opacity-100"
              leaveTo="-translate-y-1/2 scale-y-0 opacity-0"
            >
              <Disclosure.Panel className="drop-down-text">
                <Typography variant="h6" color="white">
                  Transparência Geedee
                </Typography>
                <InformationLinedDiv>
                  <Typography variant="btnLarge" color="white">
                    Energia Elétrica Injetada
                  </Typography>
                  <Typography variant="btnLarge" color="white">
                    {kilowatts} KWh
                  </Typography>
                </InformationLinedDiv>
                <InformationLinedDiv>
                  <Typography variant="pLarge" color="white">
                    Tarifa da distribuidora com imposto
                  </Typography>
                  <Typography variant="pLarge" color="white">
                    {value} / kWh
                  </Typography>
                </InformationLinedDiv>
                <InformationLinedDiv>
                  <div className="items-left flex flex-col gap-2 lg:flex-row lg:items-center">
                    <Typography variant="pLarge" color="white">
                      Tarifa Compensável
                    </Typography>
                    <Popover className="z-100 relative">
                      <Popover.Button>
                        <BsQuestion
                          className="mt-1 rounded-full bg-white"
                          color="primary"
                          size={22}
                        />
                      </Popover.Button>

                      <Popover.Panel className="z-100 absolute w-64 rounded bg-gray-900 p-5 lg:w-96">
                        <Typography variant="pLarge" color="white">
                          A tarifa compensável é definida como a tarifa da distribuidora subtraída
                          do ICMS da TUSD
                        </Typography>
                      </Popover.Panel>
                    </Popover>
                  </div>

                  <Typography variant="pLarge" color="white">
                    {expensableTariff} / kWh
                  </Typography>
                </InformationLinedDiv>
                <InformationLinedDiv>
                  <Typography variant="pLarge" color="white">
                    Desconto (sobre a conta da distribuidora)
                  </Typography>
                  <Typography variant="pLarge" color="white">
                    {porcentageGeedee().toFixed(2)} %
                  </Typography>
                </InformationLinedDiv>
                {/* <InformationLinedDiv>
                  <Typography variant="pLarge" color="white">
                    Tarifa Geedee
                  </Typography>
                  <Typography variant="pLarge" color="white">
                    {moneyFormatter(totalKwh)} / kWh
                  </Typography>
                </InformationLinedDiv> */}
                <InformationLinedDiv>
                  <Typography variant="btnLarge" color="white">
                    Total
                  </Typography>
                  <Typography variant="btnLarge" color="white">
                    {total}
                  </Typography>
                </InformationLinedDiv>
              </Disclosure.Panel>
            </Transition>
          </>
        )}
      </Disclosure>
    </Container>
  );
};

export default DropDownCard;
