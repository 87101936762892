import styled from "styled-components";

export const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 9rem 10rem;
  gap: 1rem;

  @media screen and (max-width: 768px) {
    padding: 115px 24px 64px 24px;
  }
`;

export const HeaderInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
`;

export const ButtonContainer = styled.div`
  display: flex;
  max-width: 12rem;
  padding-top: 1rem;
  margin: auto;
  align-items: center;
  justify-content: center;
`;

export const ListSection = styled.div`
  min-width: 50%;
  display: flex;
  justify-content: space-between;
  padding-top: 64px;
`;

export const FiltersContainer = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
`;
