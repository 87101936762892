import styled from "styled-components";

import theme from "@/constants/theme";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 240px);
  padding: 60px 96px 0px 96px;

  .back-button {
    width: 136px;
  }

  .back-button-mobile {
    display: none;
  }

  .header {
    width: 100%;
    height: auto;
    padding-top: 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .subtitle {
      width: 40%;
    }
  }

  .cards {
    width: 100%;
    height: auto;
    padding-top: 64px;

    .card {
      height: auto;
      padding-bottom: 8px;
    }
  }

  .email {
    width: 100%;
    height: 312px;
    display: flex;
    justify-content: center;
    align-items: center;

    .email-position {
      width: 75%;
    }
  }

  @media (max-width: 450px) {
    padding: 16px 16px 32px 16px;

    .back-button {
      display: none;
    }

    .back-button-mobile {
      width: 40px;
      height: 40px;
      background-color: ${theme.colors.black};
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      font-size: 18px;
    }

    .header {
      padding-top: 32px;
      display: flex;
      align-items: flex-start;
      flex-direction: column;

      .subtitle {
        width: 100%;
        padding-top: 20px;
      }
    }

    .cards {
      padding-top: 32px;
    }

    .email {
      width: 100%;
      height: 200px;
      display: flex;
      justify-content: center;
      align-items: center;

      .email-position {
        width: 100%;
      }
    }
  }

  .no-cards {
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 16px;
    margin: 64px 0px 8px 0px;
  }
`;
