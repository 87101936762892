import styled from "styled-components";

import theme from "@/constants/theme";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 115px 24px 0px 24px;

  .nav-bar-center {
    width: 100%;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const Infos = styled.div`
  width: 80%;
  height: auto;

  .presentation {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 20px 0px 50px 0px;

    .subtitle {
      width: 50%;
      padding-left: 88px;
    }
  }

  hr {
    width: 100%;
    height: 2px;
    background-color: ${theme.colors.grey200};
  }

  @media (max-width: 450px) {
    width: 90%;

    .presentation {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 20px 0px 50px 0px;

      .subtitle {
        width: 100%;
        padding-left: 0px;
        padding-top: 20px;
      }
    }
  }
`;

export const UserInfos = styled.div`
  width: 100%;
  height: auto;

  .user-type {
    width: 100%;
    height: auto;
    padding: 20px 0px 50px 0px;
    display: flex;
    align-items: center;

    .circle-icon {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: ${theme.colors.primary500};
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      font-size: 18px;
      margin-right: 20px;
    }
  }

  .info {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    padding-bottom: 40px;
  }

  .email-phone {
    display: flex;
    justify-content: center;
  }

  .card-one {
    width: 100%;
    height: auto;
    padding-bottom: 72px;
  }

  .card-two {
    width: 100%;
    height: auto;
    padding: 0px 0px 72px 0px;
    display: flex;
    justify-content: center;

    .card-two-center {
      width: 80%;
      height: auto;
    }
  }

  .loading-center {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 200px 0px 250px 0px;

    .loading-size {
      width: 100px;
      height: 100px;
    }
  }

  @media (max-width: 450px) {
    .card-two {
      .card-two-center {
        width: 100%;
      }
    }
  }
`;

export const ConsumerExtraInfos = styled.div`
  width: 100%;
  height: auto;
  padding-bottom: 72px;

  .number-complement,
  .city-state {
    display: flex;
  }

  .card-two {
    padding: 0px;
  }

  .consumer-unit-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .carousel-buttons {
    width: 96px;
    display: flex;
    justify-content: space-between;
    padding: 20px 24px 50px 0px;

    .button-left,
    .button-right {
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background-color: ${theme.colors.black};
      color: white;
      font-size: 20px;
      cursor: pointer;
    }
  }
`;

export const ConsumerUnit = styled.div`
  min-width: 200px;
  padding-bottom: 0px;
  margin-bottom: 60px;
  overflow-x: hidden;
  display: flex;
  flex-direction: row;
  scroll-behavior: smooth;

  ::-webkit-scrollbar {
    display: none;
  }

  .facility-card-width {
    min-width: 100%;
    padding-right: 24px;
    overflow: hidden;

    .facility-card {
      border-radius: 32px;
      background-color: white;
      padding: 24px;
      overflow: hidden;
    }
  }
`;

export const Background = styled.div<{ $displayFlex: boolean }>`
  width: 100%;
  height: 100%;
  background-color: #1e1e1e;
  opacity: 60%;
  position: absolute;
  top: 0;
  display: ${(props) => (props.$displayFlex ? "" : "none")};
`;
export const ChangePassword = styled.div<{ $displayFlex: boolean }>`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  display: ${(props) => (props.$displayFlex ? "flex" : "none")};
  justify-content: center;
  align-items: center;

  .window {
    width: 50%;
    border-radius: 24px;
    background-color: ${theme.colors.primary500};

    .window-header {
      width: 100%;
      height: 196px;
      padding: 40px;

      .subtitle {
        width: 60%;
      }

      .title-icon {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .window-circle-icon {
          width: 40px;
          height: 40px;
          background-color: ${theme.colors.black};
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
          font-size: 20px;
          cursor: pointer;
        }
      }
    }

    .change-password-forms {
      width: 100%;
      background-color: white;
    }
  }
`;
export const Form = styled.form`
  width: 100%;
  border-radius: 24px;
  background-color: white;
  padding: 40px;

  .input {
    padding-bottom: 24px;
  }

  .form-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-weight: 700;

    .submit-button {
      padding-left: 20px;
    }

    .cancel-button {
      cursor: pointer;
    }
  }
`;

export const Warning = styled.div<{ display: string }>`
  width: calc(100% - 16px);
  position: fixed;
  bottom: 61px;
  display: ${(props) => props.display};
  justify-content: center;

  .warning-center {
    width: 80%;
  }
`;
