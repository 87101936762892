import theme from "@/constants/theme";
import { colorType } from "@/constants/types";

export interface SimpleButtonProps {
  variant: VariantType;
  status?: StatusType;
  className?: string;
  label: string;
  backgroundColor?: colorType;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

export type VariantType = "cta" | "primary" | "secondary" | "simple" | "back";

export type StatusType = "disabled" | "loading" | "enable";

const proportionalButtonHeight = (size: number): string => {
  return `${size}px`;
};

export const variantHeight: { [id: string]: string } = {
  cta: proportionalButtonHeight(56),
  primary: proportionalButtonHeight(40),
  secondary: proportionalButtonHeight(36),
  simple: proportionalButtonHeight(40),
  back: proportionalButtonHeight(40),
  none: proportionalButtonHeight(40),
};

export const variantColor: { [id: string]: string } = {
  loading: theme.colors.grey800,
  disabled: theme.colors.grey200,
  enable: theme.colors.black,
};
