import styled from "styled-components";

export const FooterDiv = styled.div`
  background: ${({ theme }) => theme.colors.black};
  height: 240px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  position: relative;
  padding: 0;
  margin: 0;
  bottom: 0 !important;

  @media (max-width: 545px) {
    flex-direction: column;
    text-align: center;
  }
`;
