import React from "react";

import Typography from "@/components/Typography";

import SimpleButton from "../SimpleButton";
import { Container } from "./styles";
import { ButtonBarProps } from "./types";

const ButtonBar = ({ text, buttonLabel, backgroundColor, onClick }: ButtonBarProps) => {
  return (
    <Container background={backgroundColor}>
      <Typography variant="h5" color="black">
        {text}
      </Typography>
      <div className="button">
        <SimpleButton variant="cta" label={buttonLabel} onClick={onClick} />
      </div>
    </Container>
  );
};

export default ButtonBar;
