import React, { useRef } from "react";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";

import Typography from "@/components/Typography";

import MonthCard from "./EconomyMonths";
import { Button, Buttons, Container, MonthDiv, TextDiv, TextDivInfo } from "./styles";

const EconomyCard = ({ monthlyInfos }: { monthlyInfos: any[] }) => {
  const slide = useRef<HTMLInputElement>(null);

  const HandleLeftClick = (event: React.MouseEvent) => {
    event.preventDefault();
    if (slide && slide.current) {
      slide.current.scrollLeft -= slide.current.offsetWidth;
    }
  };

  const HandleRightClick = (event: React.MouseEvent) => {
    event.preventDefault();
    if (slide && slide.current) {
      slide.current.scrollLeft += slide.current.offsetWidth;
    }
  };

  const formatMonth = (month: string) => {
    return new Date(2023, Number(month.split("-")[1]) - 1, 1)
      .toLocaleDateString("pt-BR", {
        month: "long",
      })
      .toLocaleUpperCase();
  };

  return (
    <Container>
      <TextDiv>
        <Typography variant="h6">Descubra sua economia com a Geedee.</Typography>
        <TextDivInfo>
          <Typography variant="overline">Distribuidora</Typography>

          <Typography variant="overline">Geedee</Typography>

          <Typography variant="overline">Desconto</Typography>
        </TextDivInfo>
      </TextDiv>
      <MonthDiv ref={slide}>
        {monthlyInfos?.map((monthInfo, id) => (
          <MonthCard
            key={id}
            month={formatMonth(monthInfo.reference_month)}
            distributorPrice={monthInfo.distributor_value}
            price={monthInfo.geedee_value}
            discount={monthInfo.discount}
          />
        ))}
      </MonthDiv>
      <Buttons>
        <Button onClick={(e) => HandleLeftClick(e)}>
          <AiOutlineArrowLeft color="white" size={20} />
        </Button>
        <Button onClick={(e) => HandleRightClick(e)}>
          <AiOutlineArrowRight color="white" size={20} />
        </Button>
      </Buttons>
    </Container>
  );
};

export default EconomyCard;
