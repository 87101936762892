import { InputHTMLAttributes } from "react";
import styled from "styled-components";

interface TextFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  $error?: boolean;
  errorMessage?: string;
  password?: boolean;
  label?: string;
  helpText?: string;
  disabled?: boolean;
}

export const InputLabel = styled.p<TextFieldProps>`
  position: absolute;
  top: 5px;
  left: 25px;
  color: ${({ theme, $error }) => ($error ? theme.colors.danger200 : theme.colors.black)};
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
`;

export const TextField = styled.input<TextFieldProps>`
  width: 100%;
  height: 100%;
  border: 0.01em solid
    ${({ theme, $error }) => ($error ? theme.colors.danger200 : theme.colors.grey200)};
  border-radius: 10px;
  padding: 25px 25px 20px 25px;
  font-size: 16px;
  background: ${({ theme, disabled }) => (disabled ? theme.colors.grey200 : theme.colors.white)};
  border-radius: 40px;

  &:focus {
    outline: none !important;
    border: 0.1em solid
      ${({ theme, $error }) => ($error ? theme.colors.danger200 : theme.colors.secondary300)};
  }
  &:focus ~ ${InputLabel}, &:not(:placeholder-shown)&:not(:focus) ~ ${InputLabel} {
    font-size: 12px;
    transition: 0.4s;
    left: 25px;
  }
`;

export const Field = styled.div<TextFieldProps>`
  width: 100%;
  height: auto;
  position: relative;
`;

export const HelperText = styled.span<TextFieldProps>`
  font-size: 12px;
  margin-left: 16px;
  color: ${({ $error, theme }) => ($error ? theme.colors.danger200 : theme.colors.black)};
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
`;

export const Button = styled.span<TextFieldProps>`
  width: 50px;
  height: 50px;
  position: absolute;
  top: 7.5px;
  right: 5px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
