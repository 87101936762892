import React from "react";

import Typography from "@/components/Typography";
import theme from "@/constants/theme";

import { Box, StatusBox } from "./style";
import { ContaInfosProps } from "./types";

const ContaInfos = ({ info, image, title }: ContaInfosProps) => {
  return (
    <Box>
      {image && <div className="image">{image}</div>}
      <div className="infos">
        <Typography color="grey500" variant="overline">
          <div className="font-bold">{title.toUpperCase()}</div>
        </Typography>
        {info === "Pago" || info === "Em aberto" || info === "Ativo" || info === "Inativo" ? (
          info === "Pago" || info === "Ativo" ? (
            <StatusBox style={{ backgroundColor: theme.colors.success200 }}>
              <Typography color="black" variant="pLarge">
                {info}
              </Typography>
            </StatusBox>
          ) : (
            <StatusBox style={{ backgroundColor: theme.colors.danger200 }}>
              <Typography color="white" variant="pLarge">
                {info}
              </Typography>
            </StatusBox>
          )
        ) : (
          <Typography color="black" variant="pLarge">
            {title === "KILOWATTS" ? info + " kWh" : title === "VALOR" ? info : info}
          </Typography>
        )}
      </div>
    </Box>
  );
};

export default ContaInfos;
