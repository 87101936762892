import styled from "styled-components";

import theme from "@/constants/theme";

import { BillsToPayProps } from "./types";

const backgroundColor = ({ variant }: BillsToPayProps) => {
  if (variant == "pay") {
    return theme.colors.secondary300;
  }
  if (variant == "in-day") {
    return theme.colors.primary100;
  }
  if (variant == "late") {
    return theme.colors.danger100;
  }
};

export const Containter = styled.div<BillsToPayProps>`
  width: 100%;
  height: 224px;
  border-radius: 24px;
  background-color: ${(props) => backgroundColor(props)};
  display: flex;
  justify-content: space-between;
  overflow: hidden;

  .infos {
    width: auto;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding-left: 64px;

    .text {
      width: 60%;
    }
  }

  .circles-position {
    width: 550px;
    height: auto;
    position: relative;
  }

  .next-bill-center {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0%;
    display: flex;
    justify-content: center;
    align-items: center;

    .next-bill {
      width: 60%;
      height: 48px;
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 16px;
    }
  }

  @media (max-width: 450px) {
    height: 250px;
    flex-direction: column;

    .circles-position {
      width: 550px;
      height: auto;
      position: relative;
      top: 120px;
      right: 300px;
      display: none;
    }

    .infos {
      padding-left: 20px;

      .text {
        width: 80%;
      }
    }
  }
`;

export const Button = styled.div`
  height: 224px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0px;
  right: 100px;
  cursor: pointer;

  .button-position {
    width: 256px;
    height: 56px;
    background-color: ${theme.colors.black};
    border-radius: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
  }

  .icon {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: ${theme.colors.white};
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${theme.colors.black};
    padding: 0px;
  }

  @media (max-width: 450px) {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    display: flex;
    cursor: pointer;
    position: relative;

    .button-position {
      width: 70%;
      height: 56px;
      background-color: ${theme.colors.black};
      border-radius: 56px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px;
      position: absolute;
      margin-top: 30px;
      margin-bottom: 20px;
    }

    .icon {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: ${theme.colors.white};
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${theme.colors.black};
      padding: 0px;
    }
  }
`;

export const ButtonResponsive = styled.div`
  display: none;

  @media (max-width: 450px) {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    display: flex;
    cursor: pointer;
    position: relative;

    .button-position {
      width: 70%;
      height: 56px;
      background-color: ${theme.colors.black};
      border-radius: 56px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px;
      position: absolute;
      margin-top: 30px;
      margin-bottom: 20px;
    }

    .icon {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: ${theme.colors.white};
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${theme.colors.black};
      padding: 0px;
    }
  }
`;
