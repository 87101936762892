import { Head, router } from "@inertiajs/react";
import React, { MouseEvent, useEffect, useRef, useState } from "react";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";
import { RxHamburgerMenu } from "react-icons/rx";

import ButtonBar from "@/components/Buttons/ButtonBar";
import SimpleButton from "@/components/Buttons/SimpleButton";
import StepCard from "@/components/Cards/StepCard";
import CirclesSmallComponent from "@/components/Circles/CirclesSmallComponent";
import Footer from "@/components/Footer";
import Logo from "@/components/Logo";
import Typography from "@/components/Typography";

import EnvironmentalBlurOne from "../../../public/EnvironmentalBlurOne.png";
import EnvironmentalBlurTwo from "../../../public/EnvironmentalBlurTwo.png";
import GroupBlur from "../../../public/GroupBlur.png";
import LandingPageBenefits from "../../../public/LandingPageBenefits.png";
import {
  Accession,
  Bar,
  Benefits,
  Container,
  Environment,
  Group,
  Header,
  Introdution,
  Partners,
  SideBar,
} from "./styles";

export default function Home() {
  const [whiteHeader, setWhiteHeader] = useState(false);
  const [activeBar, setActiveBar] = useState(true);
  const carousel = useRef<HTMLDivElement>(null);
  const [sectionBackgroundHeader, setSectionBackgroundHeader] = useState("");

  function ActiveSidebar() {
    setActiveBar(!activeBar);
  }

  function ClickLeft(e: MouseEvent) {
    e.preventDefault();
    if (carousel.current != null) {
      carousel.current.scrollLeft -= carousel.current.offsetWidth - 60;
    }
  }

  function ClickRight(e: MouseEvent) {
    e.preventDefault();
    if (carousel.current != null) {
      carousel.current.scrollLeft += carousel.current.offsetWidth - 60;
    }
  }

  useEffect(function () {
    function PositionScroll() {
      if (window.scrollY > 40) {
        setWhiteHeader(true);
      } else {
        setWhiteHeader(false);
      }
    }

    window.addEventListener("scroll", PositionScroll);
  });

  function DiscoverPosition(id: string) {
    if (typeof document != "undefined") {
      const element = document.querySelector(`#${id}`);
      if (element != null) {
        const rect = element.getBoundingClientRect();
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        const position = rect.top + scrollTop;
        return Math.floor(position) - 100;
      }
    }
  }

  const positionBenefits = DiscoverPosition("benefits");
  const positionAccession = DiscoverPosition("accession");
  const positionEnvironment = DiscoverPosition("environment");
  const positionPartners = DiscoverPosition("partners");
  const positionGroup = DiscoverPosition("group");

  useEffect(function () {
    function changeColor() {
      if (
        positionBenefits != undefined &&
        positionAccession != undefined &&
        positionEnvironment != undefined &&
        positionPartners != undefined &&
        positionGroup != undefined
      ) {
        if (window.scrollY < positionBenefits && positionBenefits) {
          setSectionBackgroundHeader("");
        }
        if (window.scrollY >= positionBenefits && window.screenY < positionAccession) {
          setSectionBackgroundHeader("section-benefits");
        }
        if (window.scrollY >= positionAccession && window.screenY < positionEnvironment) {
          setSectionBackgroundHeader("section-accession");
        }
        if (window.scrollY >= positionEnvironment && window.screenY < positionPartners) {
          setSectionBackgroundHeader("section-environment");
        }
        if (window.scrollY >= positionPartners && window.screenY < positionGroup) {
          setSectionBackgroundHeader("section-partners");
        }
        if (window.scrollY >= positionGroup - 300) {
          setSectionBackgroundHeader("section-group");
        }
      }
    }

    window.addEventListener("scroll", changeColor);
  });

  function scrollToElement(id: string) {
    const element = document.querySelector(`${id}`);
    if (element != null) {
      element.scrollIntoView({ behavior: "smooth" });
    }
    setActiveBar(true);
  }

  return (
    <>
      {" "}
      <Head>
        <title>Geedee</title>
        <meta name="description" content="Gestão de Geração Distribuida Inteligente" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <main>
        <Header $scroll={whiteHeader} $sectionBackground={sectionBackgroundHeader}>
          <div className="header-center">
            <a onClick={() => scrollToElement("#introduction")}>
              <div className="logo">
                <Logo marginClass="ml-3 mr-2" />
              </div>
            </a>
            <SideBar $active={activeBar}>
              <div className="icon-circle">
                <RxHamburgerMenu onClick={ActiveSidebar} />
              </div>
              <div className="deactivate-sidebar" onClick={ActiveSidebar} />
              <Bar $active={activeBar}>
                <a onClick={() => router.get("/new_users/login")}>
                  <Typography variant="h6" color="white">
                    Acessar
                  </Typography>
                </a>
                <a onClick={() => scrollToElement("#benefits")}>
                  <Typography variant="h6" color="white">
                    Vantagens
                  </Typography>
                </a>
                <a onClick={() => scrollToElement("#accession")}>
                  <Typography variant="h6" color="white">
                    Adesão
                  </Typography>
                </a>
                <a onClick={() => scrollToElement("#environment")}>
                  <Typography variant="h6" color="white">
                    Meio ambiente
                  </Typography>
                </a>
                <a onClick={() => scrollToElement("#partners")}>
                  <Typography variant="h6" color="white">
                    Parceiros
                  </Typography>
                </a>
                <a onClick={() => scrollToElement("#group")}>
                  <Typography variant="h6" color="white">
                    Sobre
                  </Typography>
                </a>
                <div className="sidebar-circles">
                  <CirclesSmallComponent variant="grey" />
                </div>
              </Bar>
            </SideBar>
            {whiteHeader ? (
              <div className="sections">
                <div className="section" id="section-benefits">
                  <a onClick={() => scrollToElement("#benefits")}>
                    <Typography
                      variant="pLarge"
                      color={sectionBackgroundHeader == "section-benefits" ? "white" : "black"}
                    >
                      Vantagens
                    </Typography>
                  </a>
                </div>
                <div className="section" id="section-accession">
                  <a onClick={() => scrollToElement("#accession")}>
                    <Typography
                      variant="pLarge"
                      color={sectionBackgroundHeader == "section-accession" ? "white" : "black"}
                    >
                      Adesão
                    </Typography>
                  </a>
                </div>
                <div className="section" id="section-environment">
                  <a onClick={() => scrollToElement("#environment")}>
                    <Typography
                      variant="pLarge"
                      color={sectionBackgroundHeader == "section-environment" ? "white" : "black"}
                    >
                      Meio ambiente
                    </Typography>
                  </a>
                </div>
                <div className="section" id="section-partners">
                  <a onClick={() => scrollToElement("#partners")}>
                    <Typography
                      variant="pLarge"
                      color={sectionBackgroundHeader == "section-partners" ? "white" : "black"}
                    >
                      Parceiros
                    </Typography>
                  </a>
                </div>
                <div className="section" id="section-group">
                  <a onClick={() => scrollToElement("#group")}>
                    <Typography
                      variant="pLarge"
                      color={sectionBackgroundHeader == "section-group" ? "white" : "black"}
                    >
                      Sobre
                    </Typography>
                  </a>
                </div>
              </div>
            ) : (
              <></>
            )}
            <div className="login">
              {/*<div className="sign-up cursor-pointer" onClick={() => router.get("/SignUp")}>*/}
              {/*  <Typography variant="pLarge" color="white">*/}
              {/*    Cadastro*/}
              {/*  </Typography>*/}
              {/*</div>*/}
              <div
                className="sign-in cursor-pointer"
                onClick={() => {
                  router.get("/new_users/login");
                }}
              >
                <Typography variant="pLarge" color="black">
                  Acessar
                </Typography>
              </div>
            </div>
          </div>
        </Header>
        <Container>
          <Introdution id="introduction">
            <div className="presentation">
              <div className="presentation-title">
                <Typography variant="h1" color="white">
                  Reduza a sua conta de luz em poucos cliques.
                </Typography>
              </div>
              <div className="presentation-description">
                <Typography variant="h6" color="white">
                  Economize na sua conta de luz utilizando energia limpa. E o melhor, sem custos!
                </Typography>
              </div>
              <div className="presentation-button">
                <SimpleButton variant="cta" label="Quero economizar" />
              </div>
            </div>
            <div className="image" />
          </Introdution>
          <Benefits id="benefits">
            <div className="benefits-title">
              <Typography variant="h2" color="black">
                Vantagens da assinatura
              </Typography>
            </div>
            <div className="benefits-list">
              <div className="topic">
                <Typography variant="h6" color="black">
                  Sem prazo de fidelidade
                </Typography>
                <div className="benefits-circle">
                  <Typography variant="pLarge" color="black">
                    01
                  </Typography>
                </div>
              </div>
              <div className="topic">
                <Typography variant="h6" color="black">
                  Sem custos e sem burocracia
                </Typography>
                <div className="benefits-circle">
                  <Typography variant="pLarge" color="black">
                    02
                  </Typography>
                </div>
              </div>
              <div className="topic">
                <Typography variant="h6" color="black">
                  Sem obras no local de consumo
                </Typography>
                <div className="benefits-circle">
                  <Typography variant="pLarge" color="black">
                    03
                  </Typography>
                </div>
              </div>
              <div className="topic">
                <Typography variant="h6" color="black">
                  Desconto mensal na conta de luz
                </Typography>
                <div className="benefits-circle">
                  <Typography variant="pLarge" color="black">
                    04
                  </Typography>
                </div>
              </div>
              <div className="topic">
                <Typography variant="h6" color="black">
                  Contratação simples e 100% digital
                </Typography>
                <div className="benefits-circle">
                  <Typography variant="pLarge" color="black">
                    05
                  </Typography>
                </div>
              </div>
              <div className="topic">
                <Typography variant="h6" color="black">
                  Método de pagamento unificado
                </Typography>
                <div className="benefits-circle">
                  <Typography variant="pLarge" color="black">
                    06
                  </Typography>
                </div>
              </div>
              <div className="topic">
                <Typography variant="h6" color="black">
                  Preserva o meio ambiente
                </Typography>
                <div className="benefits-circle">
                  <Typography variant="pLarge" color="black">
                    07
                  </Typography>
                </div>
              </div>
              <div className="benefits-button">
                <SimpleButton label="Quero economizar" variant="cta" />
              </div>
            </div>
            <div className="benefits-image">
              <img src={LandingPageBenefits} alt="benefits-image" />
            </div>
          </Benefits>
          <Accession id="accession">
            <div className="accession-top">
              <Typography variant="h2" color="black">
                Processo de adesão
              </Typography>
              <div className="carousel-buttons">
                <div className="button-left" onClick={ClickLeft}>
                  <FiArrowLeft />
                </div>
                <div className="button-right" onClick={ClickRight}>
                  <FiArrowRight />
                </div>
              </div>
            </div>
            <div className="carousel" ref={carousel}>
              <div className="carousel-card">
                <StepCard
                  title="Análise"
                  total={4}
                  step={1}
                  text="Fazemos uma análise básica para entender se podemos te atender ou não, mas fica tranquilo que é coisa simples!"
                />
              </div>
              <div className="carousel-card">
                <StepCard
                  title="Assinatura Geedee"
                  total={4}
                  step={2}
                  text="Ao assinar com a Geedee, conectamos o seu consumo de energia elétrica às nossas usinas de energia renovável da sua região."
                />
              </div>
              <div className="carousel-card">
                <StepCard
                  title="Economia"
                  total={4}
                  step={3}
                  text="Você passará a receber a conta de luz unificada da Geedee, garantindo uma economia mensal de até 20% no seu custo de energia elétrica."
                />
              </div>
              <div className="carousel-card">
                <StepCard
                  title="Economia"
                  total={4}
                  step={4}
                  text="Você passará a receber a conta de luz unificada da Geedee, garantindo uma economia mensal de até 20% no seu custo de energia elétrica."
                />
              </div>
            </div>
            <div className="accession-button">
              <div className="accession-button-center">
                <ButtonBar
                  text="Reduza a sua conta de luz em poucos cliques."
                  buttonLabel="Quero economizar"
                  backgroundColor="primary100"
                  onClick={() => {}}
                />
              </div>
            </div>
          </Accession>
          <Environment id="environment">
            <div className="environmetal-image-one">
              <img src={EnvironmentalBlurOne} alt="image-one" />
            </div>
            <div className="environment-title">
              <Typography variant="h2" color="black">
                Geedee no <br />
                meio ambiente
              </Typography>
            </div>
            <div className="environment-subtext">
              <Typography variant="h4" color="primary400">
                Todos os anos diminuímos a<br />
                emissão de carbono em
              </Typography>
              <Typography variant="big" color="secondary400">
                000 toneladas
              </Typography>
            </div>
            <div className="environment-text">
              <div className="environmental-text-position">
                <Typography variant="h4" color="primary400">
                  Estamos comprometidos a salvar nosso meio ambiente, oferecendo ao mercado o
                  consumo de uma energia limpa e que não trás danos para ele no longo prazo.
                </Typography>
              </div>
            </div>
            <div className="environment-button">
              <div className="environment-button-center">
                <ButtonBar
                  text="Reduza a sua conta de luz em poucos cliques."
                  buttonLabel="Quero economizar"
                  backgroundColor="secondary300"
                  onClick={() => {}}
                />
              </div>
            </div>
            <div className="environmetal-image-two">
              <img src={EnvironmentalBlurTwo} alt="image-one" />
            </div>
          </Environment>
          <Partners id="partners">
            <Typography variant="h3" color="black">
              Clientes e parceiros
            </Typography>
            <div className="partners-list">
              <div className="partner">
                <div className="partner-image" />
                <div className="partner-name">nome da empresa</div>
              </div>
              <div className="partner">
                <div className="partner-image" />
                <div className="partner-name">nome da empresa</div>
              </div>
              <div className="partner">
                <div className="partner-image" />
                <div className="partner-name">nome da empresa</div>
              </div>
              <div className="partner">
                <div className="partner-image" />
                <div className="partner-name">nome da empresa</div>
              </div>
              <div className="partner">
                <div className="partner-image" />
                <div className="partner-name">nome da empresa</div>
              </div>
              <div className="partner">
                <div className="partner-image" />
                <div className="partner-name">nome da empresa</div>
              </div>
            </div>
            <div className="partners-button">
              <div className="partners-button-center">
                <ButtonBar
                  text="Reduza a sua conta de luz em poucos cliques."
                  buttonLabel="Quero economizar"
                  backgroundColor="tertiary100"
                  onClick={() => {}}
                />
              </div>
            </div>
          </Partners>
          <Group id="group">
            <div className="group-title">
              <Typography variant="h3" color="black">
                Empresa do
                <br />
                grupo BRP
              </Typography>
            </div>
            <div className="group-image" />
            <div className="group-text">
              <Typography variant="pLarge" color="black">
                O Grupo BRP desenvolve e investe em projetos de energia renovável em todo Brasil.
                Sua missão é contribuir para descarbonização do nosso meio ambiente e oferecer
                alternativas sustentáveis para consumidores de energia.
              </Typography>
            </div>
            <div className="group-background-image">
              <img src={GroupBlur} alt="blur-image" />
            </div>
          </Group>
        </Container>
        <Footer />
      </main>
    </>
  );
}
