import { Listbox, Transition } from "@headlessui/react";
import React, { Fragment, useState } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";

import Typography from "../Typography";

interface DropdownProps {
  options: string[];
  onSelect: (a: string) => void;
  defaultValue?: string;
}
export default function Dropdown({ options, onSelect, defaultValue }: DropdownProps) {
  const [selected, setSelected] = useState(defaultValue ? defaultValue : options[0]);

  const onChange = (value: string) => {
    setSelected(value);
    onSelect(value);
  };
  return (
    <div className="top-16">
      <Listbox value={selected} onChange={onChange}>
        <div className="relative mt-1">
          <Listbox.Button className="flex items-center justify-center gap-2 rounded-3xl bg-white px-3 py-2">
            <Typography variant="btnLarge">{selected}</Typography>
            <MdKeyboardArrowDown className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="ring-opacity-/5 absolute mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black focus:outline-none sm:text-sm">
              {options.map((option, id) => (
                <Listbox.Option
                  key={id}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-8 pr-10 ${
                      active ? "bg-gray-200" : "text-gray-900"
                    }`
                  }
                  value={option}
                >
                  {({ selected }) => (
                    <>
                      <Typography variant="btnLarge">{option}</Typography>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600" />
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
}
