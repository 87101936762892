import { router, useForm } from "@inertiajs/react";
import axios from "axios";
import jsPDF from "jspdf";
import React, { useContext, useEffect, useState } from "react";

import ButtonIcon from "@/components/Buttons/SimpleButton";
import Layout from "@/components/Layout";
import PercentageLetterModal from "@/components/Modal/PercentageLetterModal";
import ProgressBarComponent from "@/components/ProgressBar";
import Typography from "@/components/Typography";
import { PageContainer } from "@/constants/listIndexStyle";
import { AlertContext } from "@/contexts/Alert";

const PercentageLetter = ({ letter, action = "show" }: any) => {
  const { showAlert } = useContext<any>(AlertContext);
  const [open, setOpen] = useState(false);
  const [totalSum, setTotalSum] = useState(0);
  const [percentages, setPercentages] = useState([]);
  const [barColor, setBarColor] = useState("");
  const { data, setData, post } = useForm({
    start_date: "",
    power_plant_id: letter.power_plant_id,
    facility_percentages_attributes: [],
  });
  const date = new Date(letter.start_date);
  const dateFormated = `${date.getFullYear()}-${
    date.getMonth() + 1 >= 10 ? date.getMonth() + 1 : "0" + Number(date.getMonth() + 1)
  }-${date.getDate() + 1}`;

  const handleBarColor = (sum: number) => {
    if (sum < 100) {
      return "#479657";
    } else if (sum == 100) {
      return "#A2D729";
    } else {
      return "#E95D49";
    }
  };

  useEffect(() => {
    if (action == "show") {
      setPercentages(
        letter.consumers_with_facilities.map((consumer) =>
          consumer.facilities.map((facility: any) => facility.facility_percentage),
        ),
      );

      const consumers: any = [];
      letter.consumers_with_facilities.forEach((consumer: any) => {
        consumer.facilities.forEach((facility: any) => {
          consumers.push({
            facility_id: facility.facility_id,
            percentage: facility.facility_percentage * 10,
          });
        });
      });

      setData({ ...data, facility_percentages_attributes: consumers, start_date: dateFormated });
    }
    setBarColor("");
  }, []);

  useEffect(() => {
    let sum = 0;
    sum = data.facility_percentages_attributes.reduce(
      (acc: any, item: any) => acc + item.percentage / 10,
      0,
    );
    setTotalSum(sum);
    setBarColor(handleBarColor(sum));
  }, [percentages]);

  const updateLetter = () => {
    axios
      .patch(`/manager/percentage-letter/${letter.id}`, data)
      .then(() => {
        showAlert({
          message: "Carta de rateio atualizada",
          type: "success",
        });
        setOpen(false);
      })
      .catch((e) =>
        showAlert({
          message: `erro: ${e}`,
          type: "error",
        }),
      );
  };

  const createLetter = () => {
    post("/manager/percentage-letter");
  };

  const exportLetter = () => {
    const doc = new jsPDF({ orientation: "p" });
    const page = document.getElementById("page-container") as HTMLElement;

    if (page) {
      const buttons = document.getElementById("action-buttons") as HTMLElement;
      const buttonsParent = buttons.parentNode as HTMLElement;
      const consumers = document.getElementById("consumers-container") as HTMLElement;
      const progress = document.getElementById("progress-bar") as HTMLElement;

      if (buttons && buttonsParent) {
        buttonsParent.removeChild(buttons);
      }
      const paragraph = document.createElement("p");
      paragraph.textContent = `Carta válida a partir de ${dateFormated}`;

      buttonsParent.appendChild(paragraph);
      buttonsParent.style.flexDirection = "column";
      page.style.width = "1513px";
      consumers.style.flexDirection = "column";
      progress.style.width = "50%";
      progress.style.margin = "auto";
      buttonsParent.style.textAlign = "center";
      consumers.style.alignItems = "center";
      consumers.style.marginTop = "16px";
      const canvaScale = 0.197;

      doc
        .html(page, {
          autoPaging: "text",
          callback: function (document) {
            document.save(
              letter.power_plant_name.concat(" - ", String(letter.start_date).substring(0, 7)),
            );
          },
          html2canvas: {
            allowTaint: true,
            removeContainer: false,
            backgroundColor: "#ebf2f59e",
            scale: canvaScale,
            x: 230,
          },
        })
        .then(() => {
          buttonsParent.appendChild(buttons);
          buttonsParent.removeChild(paragraph);
          page.style.width = "";
          consumers.style.flexDirection = "";
          progress.style.width = "";
          progress.style.margin = "";
          buttonsParent.style.flexDirection = "";
          buttonsParent.style.textAlign = "";
          consumers.style.alignItems = "";
          consumers.style.marginTop = "";
        });
    }
  };

  return (
    <Layout>
      <PageContainer>
        <PercentageLetterModal
          isOpen={open}
          setIsOpen={setOpen}
          powerPlantName={letter.power_plant_name}
          data={data}
          setData={setData}
          onClick={action == "show" ? updateLetter : createLetter}
          action={action}
        />
        <div className="mb-16 max-w-60">
          <ButtonIcon
            onClick={() =>
              router.get(`/manager/power-plants/${letter.power_plant_id}/percentage-letters`)
            }
            variant="back"
            label="Voltar"
          />
        </div>
        <div className="p-4" id="page-container">
          <div className="mb-8 flex w-full items-center justify-between">
            <div className="flex flex-col justify-start">
              <Typography variant="h1">Carta de Rateio</Typography>
              <Typography variant="h4" color="grey600">
                {letter.power_plant_name}
              </Typography>
            </div>
            <div className="w-56" id="action-buttons">
              {action == "create" ? (
                <ButtonIcon onClick={() => setOpen(true)} variant="simple" label="Enviar carta" />
              ) : (
                <div className="flex flex-col gap-4">
                  <ButtonIcon onClick={() => setOpen(true)} variant="simple" label="Editar carta" />
                  <ButtonIcon onClick={exportLetter} variant="simple" label="Exportar carta" />
                </div>
              )}
            </div>
          </div>
          <div
            className="mb-6 flex w-full flex-col items-center justify-center gap-2 rounded-3xl bg-white p-6 lg:flex-row"
            id="progress-bar"
          >
            <Typography variant="pLarge">Total distribuído:</Typography>
            <ProgressBarComponent percentage={Math.min(totalSum, 100)} color={barColor} />
            <Typography variant="pLarge">{totalSum} %</Typography>
          </div>
          <div
            className="flex h-fit w-full flex-row flex-wrap justify-between gap-4 rounded-3xl"
            id="consumers-container"
          >
            {letter.consumers_with_facilities.map((consumer: any, idx: number) => {
              const consumerSum =
                action == "show"
                  ? consumer.facilities.reduce(
                      (acc: any, current: any) => acc + current.facility_percentage,
                      0,
                    )
                  : data.facility_percentages_attributes
                      .filter((percentage: any) =>
                        consumer.facilities
                          .map((facility: any) => facility.facility_id)
                          .includes(percentage.facility_id),
                      )
                      .reduce((acc: any, current: any) => acc + current.percentage, 0) / 10;
              return (
                <div
                  className="flex h-fit w-full flex-col justify-center rounded-3xl bg-white md:w-[49%]"
                  key={idx}
                >
                  <div className="flex w-full flex-row items-center gap-4 border-b border-b-gray-200 p-4">
                    <div>
                      <Typography
                        variant="pLarge"
                        color="grey800"
                        className="rounded border border-gray-100 p-1 font-semibold"
                      >
                        {consumer.consumer_id}
                      </Typography>
                    </div>
                    <div className="flex flex-col">
                      <Typography variant="h6" color="black">
                        {consumer.consumer_name}
                      </Typography>
                      <Typography variant="pLarge" color="black" className="pt-0">
                        Usando: {consumerSum}%
                      </Typography>
                    </div>
                  </div>
                  {consumer.facilities.map((facility: any, index: number) => {
                    const handlePercentage = () => {
                      const inputs: any = Array.prototype.slice.call(
                        document.getElementsByClassName(`inputPercentage-${consumer.consumer_id}`),
                      );
                      setPercentages(
                        inputs.map((element: any) => parseFloat(element.value.replace(/,/g, "."))),
                      );
                      const element: any = document.getElementById(
                        `input-${facility.facility_code}`,
                      );
                      const percentage_float = parseFloat(element.value.replace(/,/g, ".")) * 10;
                      const new_facility_percentages_attributes: any = [
                        ...data.facility_percentages_attributes.filter(
                          (facility_percentage: any) =>
                            facility_percentage.facility_id != facility.facility_id,
                        ),
                        { facility_id: facility.facility_id, percentage: percentage_float },
                      ];
                      setData(
                        "facility_percentages_attributes",
                        new_facility_percentages_attributes,
                      );
                    };

                    return (
                      <div
                        className="flex w-full flex-row items-center justify-between gap-4 p-4"
                        key={index}
                      >
                        <Typography
                          variant="pLarge"
                          color="grey800"
                          className="rounded border border-gray-100 p-1 font-semibold"
                        >
                          {facility.facility_code}
                        </Typography>
                        <Typography
                          variant="pLarge"
                          color="grey900"
                          className="hidden w-full text-left sm:block"
                        >
                          {facility.facility_address}
                        </Typography>
                        <div className="flex items-center gap-2">
                          <input
                            id={`input-${facility.facility_code}`}
                            type="number"
                            className={`inputPercentage-${consumer.consumer_id} w-20 rounded-2xl border-2 p-2`}
                            defaultValue={action == "show" ? facility.facility_percentage : 0}
                            data-mask="99,9"
                            data-mask-selectonfocus="true"
                            onBlur={() => handlePercentage()}
                          />
                          <p>%</p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      </PageContainer>
    </Layout>
  );
};

export default PercentageLetter;
