import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3.5rem;
  max-width: 12rem;
  flex: none;

  @media (min-width: 2560px) {
    gap: 2rem;
  }
`;

export const CommonValueDiv = styled.div`
  background-color: ${({ theme }) => theme.colors.tertiary200};
  width: 11rem;
  padding: 0.6em;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
`;

export const GeedeeValueDiv = styled.div`
  background-color: ${({ theme }) => theme.colors.tertiary300};
  width: 11rem;
  padding: 0.6em;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  transform: translateY(-6px);
`;

export const FinalValueDiv = styled.div`
  background-color: ${({ theme }) => theme.colors.primary500};
  width: 11rem;
  padding: 0.6em;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  transform: translateY(-12px);
`;
