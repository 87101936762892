import React from "react";
import { FaRegSmileWink } from "react-icons/fa";
import { FiAlertTriangle, FiX } from "react-icons/fi";

import {
  CloseContainer,
  Container,
  IconBackground,
  SnackBarText,
} from "@/components/SnackBar/styles";
import { SnackBarProps } from "@/components/SnackBar/type";

const SnackBar = ({ message, type, onClose = undefined }: SnackBarProps) => {
  return (
    <Container type={type}>
      <IconBackground type={type}>
        {type === "success" && <FaRegSmileWink size={20} />}
        {type === "error" && <FiAlertTriangle size={20} />}
      </IconBackground>

      <div style={{ flex: 1 }}>
        <SnackBarText variant="h6">{message}</SnackBarText>
      </div>
      {onClose != undefined ? (
        <CloseContainer onClick={onClose}>
          <FiX size={24} />
        </CloseContainer>
      ) : (
        <></>
      )}
    </Container>
  );
};

export default SnackBar;
