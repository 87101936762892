import { createInertiaApp } from "@inertiajs/react";
import React from "react";
import { createRoot } from "react-dom/client";

import Layout from "../Layout";

createInertiaApp({
  resolve: async (name) => {
    const pages = import.meta.glob("../pages/**/*.tsx", { eager: true });
    let page = pages[`../pages/${name}.tsx`];
    page.default.layout = page.default.layout || ((page) => <Layout>{page}</Layout>);
    return page;
  },
  setup({ el, App, props }) {
    const container = document.getElementById(el.id);
    const root = createRoot(container);
    root.render(<App {...props} />);
  },
});
