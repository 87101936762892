import * as React from "react";
import { PropsWithChildren } from "react";

import { Text } from "./styles";
import { TypographyProps } from "./types";

const Typography = ({
  color,
  variant = "h1",
  children,
  cursorPointer,
  className,
}: PropsWithChildren<TypographyProps>) => {
  return (
    <Text variant={variant} color={color} $cursorPointer={cursorPointer} className={className}>
      {children}
    </Text>
  );
};

export default Typography;
