import styled from "styled-components";

import theme from "@/constants/theme";

import solarPanelLandingPage from "../../../public/solarPanelLandingPage.jpeg";

export const Header = styled.div<{
  $scroll: boolean;
  $sectionBackground: string;
}>`
  width: 100%;
  height: auto;
  margin-top: ${(props) => (props.$scroll ? "20px" : "64px")};
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  font-weight: 700;

  .header-center {
    transition: all ease 0.1s;
    width: 95%;
    height: 72px;
    border-radius: 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px 12px 16px;
    background-color: ${(props) => (props.$scroll ? "white" : "none")};
  }

  .logo {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${theme.colors.grey100};
    border-radius: 48px;
    cursor: pointer;
  }

  .sections {
    width: 48%;
    height: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: orange;

    .section {
      width: auto;
      padding: 3px 24px 3px 24px;
      border-radius: 32px;
      text-align: center;
      cursor: pointer;
    }

    #section-benefits {
      background-color: ${(props) =>
        props.$sectionBackground == "section-benefits" ? theme.colors.primary500 : "none"};
    }

    #section-accession {
      background-color: ${(props) =>
        props.$sectionBackground == "section-accession" ? theme.colors.primary500 : "none"};
    }

    #section-environment {
      background-color: ${(props) =>
        props.$sectionBackground == "section-environment" ? theme.colors.primary500 : "none"};
    }

    #section-partners {
      background-color: ${(props) =>
        props.$sectionBackground == "section-partners" ? theme.colors.primary500 : "none"};
    }

    #section-group {
      background-color: ${(props) =>
        props.$sectionBackground == "section-group" ? theme.colors.primary500 : "none"};
      color: ${theme.colors.white};
    }
  }

  .login {
    width: auto;
    height: auto;
    padding: 8px;
    border-radius: 48px;
    background-color: white;
    display: flex;

    .sign-up {
      width: 128px;
      height: 40px;
      border-radius: 56px;
      padding: 16px;
      background-color: ${theme.colors.black};
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    .sign-in {
      width: 128px;
      height: 40px;
      border-radius: 56px;
      padding: 16px;
      margin-left: 8px;
      background-color: ${theme.colors.grey100};
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }

  @media (max-width: 450px) {
    width: calc(100% - 32px);
    margin-top: 16px;
    left: 16px;

    .header-center {
      width: calc(100% - 16px);
    }

    .sections {
      display: none;
    }

    .login {
      display: none;
    }
  }
`;

export const SideBar = styled.div<{ $active: boolean }>`
  display: none;

  @media (max-width: 450px) {
    /* background-color: white; */
    padding: 10px;
    border-radius: 50%;
    position: relative;
    display: flex;

    .icon-circle {
      background-color: black;
      padding: 5px;
      border-radius: 50%;
      font-size: 24px;
      color: white;
    }

    .deactivate-sidebar {
      background-color: ${theme.colors.grey100};
      opacity: 50%;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      display: ${(props) => (props.$active ? "none" : "")};
    }
  }
`;

export const Bar = styled.div<{ $active: boolean }>`
  width: 70%;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  background-color: ${theme.colors.primary500};
  opacity: 100%;
  display: flex;
  flex-direction: column;
  z-index: 30;
  transform: ${(props) => (props.$active ? "translateX(500%)" : "translateX(0%)")};
  transition: all 0.5s ease-in-out;
  font-weight: 700;
  overflow: hidden;

  a {
    padding: 20px 0px 0px 16px;
  }

  .sidebar-circles {
    position: absolute;
    bottom: -100px;
    left: -20px;
  }
`;

export const Container = styled.div`
  width: 100%;
  height: auto;
  background-image: linear-gradient(to top, #d2f3f4, #d2f4f6, #ffffff, #ffffff, #ffffff, #ffffff);
  padding: 8px 8px 0px 8px;
`;

export const Introdution = styled.div`
  width: 100%;
  height: auto;
  display: flex;

  .presentation {
    width: 50%;
    padding: 289px 56px 56px 128px;
    margin-right: 4px;
    border-radius: 32px;
    display: flex;
    flex-direction: column;
    background-color: ${theme.colors.primary500};

    .presentation-title {
      width: 450px;
      padding-bottom: 32px;
    }

    .presentation-description {
      padding-bottom: 72px;
    }

    .presentation-button {
      width: 256px;
    }
  }

  .image {
    width: 50%;
    height: auto;
    border-radius: 32px;
    background-image: url(${solarPanelLandingPage});
    background-repeat: no-repeat;
    background-position: -750px -250px;
  }

  @media (max-width: 450px) {
    flex-direction: column;

    .presentation {
      width: 100%;
      padding: 112px 16px 32px 16px;

      .presentation-title {
        width: 100%;
      }

      .presentation-button {
        width: 100%;
      }
    }

    .image {
      width: 100%;
      min-height: 320px;
      margin: 4px 0px 4px 0px;
      background-position: 0px -680px;
    }
  }
`;

export const Benefits = styled.div`
  width: 100%;
  height: auto;
  background-color: ${theme.colors.tertiary100};
  display: flex;
  justify-content: space-between;
  padding: 108px 128px 72px 128px;
  border-radius: 32px;
  margin-top: 4px;
  position: relative;
  overflow: hidden;

  .benefits-image {
    position: absolute;
    left: 0px;
    bottom: -3px;
  }

  .benefits-title {
    width: 50%;
    height: auto;
    display: flex;
    justify-content: center;
    z-index: 1;
    padding-right: 40px;
  }

  .benefits-list {
    width: 50%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    z-index: 1;

    .benefits-button {
      width: 256px;
      padding-top: 56px;
    }

    .topic {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 0px 20px 0px;
      border-top: 1px solid ${theme.colors.tertiary300};

      .benefits-circle {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: ${theme.colors.tertiary300};
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  @media (max-width: 450px) {
    flex-direction: column;
    padding: 48px 16px 16px 16px;

    .benefits-title {
      width: 100%;
      padding-bottom: 32px;
    }

    .benefits-list {
      width: 100%;

      .benefits-button {
        width: 100%;
      }

      .topic {
        width: 100%;

        .benefits-circle {
          min-width: 40px;
          min-height: 40px;
          margin-left: 20px;
        }
      }
    }
  }
`;

export const Accession = styled.div`
  width: 100%;
  height: auto;
  padding: 104px 0px 72px 128px;

  .accession-top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 128px;

    .carousel-buttons {
      width: 88px;
      display: flex;
      justify-content: space-between;

      .button-left,
      .button-right {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: ${theme.colors.black};
        color: white;
        font-size: 25px;
        cursor: pointer;
      }
    }
  }

  .carousel {
    width: 100%;
    display: flex;
    overflow-x: auto;
    padding-top: 72px;
    scroll-behavior: smooth;

    .carousel-card {
      min-width: 420px;
      padding-right: 16px;
    }
  }

  .carousel::-webkit-scrollbar {
    display: none;
  }

  .accession-button {
    width: 100%;
    padding: 104px 128px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 450px) {
    height: auto;
    padding: 72px 0px 16px 16px;

    .accession-top {
      width: 100%;
      padding-right: 16px;

      .carousel-buttons {
        display: none;
      }
    }

    .carousel {
      .carousel-card {
        min-width: 350px;
        padding-right: 16px;
      }
    }

    .accession-button {
      width: 100%;
      padding: 40px 16px 0px 0px;

      .accession-button-center {
        width: 100%;
      }
    }
  }

  @media (max-width: 370px) {
    .carousel {
      .carousel-card {
        min-width: 300px;
        padding-right: 16px;
      }
    }
  }
`;

export const Environment = styled.div`
  width: 100%;
  height: 900px;
  background-color: ${theme.colors.secondary100};
  border-radius: 32px;
  margin-top: 8px;
  padding: 80px 104px 30px 104px;
  position: relative;
  overflow: hidden;

  .environmetal-image-one {
    position: absolute;
    right: 0;
    top: 0;
  }

  .environment-title {
    width: 100%;
    position: absolute;
  }

  .environment-subtext {
    width: 90%;
    padding-top: 130px;
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 1;
  }

  .environment-text {
    width: 100%;
    padding-top: 350px;
    display: flex;
    justify-content: flex-end;

    .environmental-text-position {
      width: 576px;
      position: absolute;
      z-index: 1;
    }
  }

  .environment-button {
    width: calc(100% - 208px);
    padding-top: 300px;
    display: flex;
    justify-content: center;
    position: absolute;
    z-index: 1;
  }

  .environmetal-image-two {
    position: absolute;
    left: 0;
    bottom: -3px;
  }

  @media (max-width: 450px) {
    min-height: 1130px;
    padding: 48px 16px 40px 16px;

    .environment-title {
      width: 100%;
      z-index: 1;
      position: absolute;
    }

    .environment-subtext {
      width: calc(100% - 32px);
      padding-top: 170px;
      align-items: center;
      text-align: center;
    }

    .environment-text {
      width: 100%;
      padding-top: 450px;
      display: flex;
      justify-content: center;

      .environmental-text-position {
        width: 100%;
        padding: 48px 16px 30px 16px;
      }
    }

    .environment-button {
      width: calc(100% - 32px);
      position: absolute;
      bottom: 30px;
    }
  }

  @media (max-width: 420px) {
    min-height: 1230px;
  }

  @media (max-width: 370px) {
    min-height: 1330px;
  }
`;

export const Partners = styled.div`
  width: 100%;
  height: auto;
  background-color: white;
  border-radius: 32px;
  margin-top: 8px;
  padding: 144px 128px 104px 128px;

  .partners-list {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;

    .partner {
      width: 181px;
      height: auto;
      padding: 64px 0px 0px 0px;
      margin-left: 16.4px;
      display: flex;
      align-items: center;
      flex-direction: column;
      text-align: center;

      .partner-image {
        width: 100%;
        height: 68px;
        border-radius: 8px;
        background-color: ${theme.colors.grey100};
      }
    }
  }

  .partners-button {
    width: 100%;
    padding-top: 168px;
    display: flex;
    justify-content: center;
  }

  @media (max-width: 450px) {
    padding: 48px 16px 20px 16px;
    margin: none;
    text-align: center;

    .partners-list {
      flex-direction: column;
      align-items: center;
      padding: 0px;
    }

    .partner {
      padding: 24px 0px 0px 0px;
    }

    .partners-button {
      padding-top: 40px;
    }
  }
`;

export const Group = styled.div`
  width: 100%;
  height: 479px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 128px 0px 128px;
  position: relative;
  overflow: hidden;

  .group-text {
    width: 446px;
    z-index: 1;
  }

  .group-image {
    width: 181px;
    height: 68px;
    border-radius: 8px;
    background-color: ${theme.colors.black};
    margin: 0px 30px 0px 30px;
    z-index: 1;
  }

  .group-background-image {
    position: absolute;
    right: 0;
    bottom: -3px;
  }

  @media (max-width: 450px) {
    height: 600px;
    margin-top: 56px;
    padding: 10px 16px 100px 16px;
    flex-direction: column;
    align-items: flex-start;

    .group-title {
      width: 100%;
      padding-top: 50px;
      border-top: 1px solid #90bcbd;
    }

    .group-image {
      margin: 0px 30px 0px 0px;
    }

    .group-text {
      width: 90%;
      z-index: 1;
    }
  }
`;
