import { router, useForm } from "@inertiajs/react";
import React, { FormEvent, useContext, useState } from "react";

import SimpleButton from "@/components/Buttons/SimpleButton";
import { StatusType } from "@/components/Buttons/SimpleButton/types";
import Logo from "@/components/Logo";
import TextFieldComponent from "@/components/TextField";
import Typography from "@/components/Typography";
import { AlertContext } from "@/contexts/Alert";

import { Access, Container, Form, Img, Infos, LogoContainer } from "./styles";

export default function Forgot() {
  const { showAlert } = useContext<any>(AlertContext);
  const [buttonStatus, setButtonStatus] = useState<StatusType>("disabled");

  const { data, setData, post } = useForm({
    email: "",
  });

  const submitPasswordData = (e: FormEvent) => {
    e.preventDefault();
    setButtonStatus("loading");
    post("/new_users/password", {
      onFinish: () => {
        setButtonStatus("enable");
      },
      onSuccess: () => {
        showAlert({
          message: "Enviamos o passo a passo para recuperar sua senha no e-mail informado",
          type: "success",
        });
      },
      onError: (e) => {
        showAlert({
          message: e,
          type: "error",
        });
      },
    });
  };

  return (
    <Container>
      <div className="grid h-full grid-cols-1 justify-between gap-2 md:grid-cols-3">
        <Infos className="col-span-2 grid grid-cols-1 md:grid-cols-3">
          <LogoContainer onClick={() => router.get("/")}>
            <Logo size="h5" />
          </LogoContainer>
          <Access className="col-span-2 mx-auto w-full">
            <Form onSubmit={submitPasswordData} className="w-full">
              <div className="header">
                <Typography variant="h3" color="grey800">
                  Recuperar Senha
                </Typography>
                <Typography variant="pLarge" color="grey500">
                  Esqueceu sua senha? Não tem problema. Preencha o formulário abaixo e te
                  ajudaremos.
                </Typography>
              </div>
              <div className="forms-input">
                <div className="input">
                  <TextFieldComponent
                    disabled={false}
                    label="Seu e-mail"
                    error={false}
                    required
                    type="email"
                    value={data.email}
                    onChange={(e) => {
                      e.target.value === ""
                        ? setButtonStatus("disabled")
                        : setButtonStatus("enable");
                      setData("email", e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="button">
                <SimpleButton variant="cta" status={buttonStatus} label="Recuperar Senha" />
              </div>
            </Form>
          </Access>
        </Infos>
        <Img className="invisible w-full md:visible">
          <div className="image w-full" />
        </Img>
      </div>
    </Container>
  );
}
