import React from "react";
import { useTheme } from "styled-components";
import { VictoryLabel, VictoryPie } from "victory";

import Typography from "@/components/Typography";
import { moneyFormatterWithoutCurrency } from "@/utils/moneyFormat";

interface ExpensesGraphProps {
  payedExpenses: number;
  lateExpenses: number;
  overdueExpenses: number;
  debtsTotal: number;
  debtsAverage: number;
}

const ExpensesGraph = ({
  lateExpenses,
  overdueExpenses,
  payedExpenses,
  debtsTotal,
  debtsAverage,
}: ExpensesGraphProps) => {
  const theme = useTheme();

  const totalExpenses = lateExpenses + overdueExpenses + payedExpenses;

  const expensesData = [
    {
      name: "CONTA PAGA",
      value: payedExpenses,
      color: theme.colors.tertiary300,
    },
    {
      name: "CONTA ATRASADAS",
      value: lateExpenses,
      color: theme.colors.warning200,
    },
    {
      name: "INADIPLENTE",
      value: overdueExpenses,
      color: theme.colors.danger200,
    },
    {
      name: "TOTAL DE CONTAS",
      value: totalExpenses,
      color: theme.colors.grey700,
    },
  ];

  const graphData = () => {
    if (totalExpenses == 0) return [{ x: "Sem dados", y: 1 }];

    return [
      { x: "CONTA PAGA", y: payedExpenses },
      { x: "CONTA ATRASADAS", y: lateExpenses },
      { x: "INADIPLENTE", y: overdueExpenses },
    ];
  };

  const labelRadius = ({ datum }: any) => {
    if (totalExpenses == 0) return 0.1;
    if (datum.xName === "INADIPLENTE") return 70;

    return 50;
  };

  const label = ({ datum }: any) => {
    if (totalExpenses == 0) return "Sem Dados";

    return `${((100 * datum.y) / totalExpenses).toFixed(0)}%`;
  };
  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-5">
        <div className="flex justify-center md:col-span-3">
          <svg height={500} width={400} className="mr-0">
            <VictoryPie
              standalone={false}
              height={500}
              animate={{
                duration: 2000,
              }}
              colorScale={[
                theme.colors.tertiary300,
                theme.colors.warning200,
                theme.colors.danger200,
              ]}
              labelComponent={
                <VictoryLabel
                  style={{
                    font: "Inter",
                    fontWeight: "bold",
                    fill: theme.colors.black,
                  }}
                />
              }
              labels={({ datum }) => label({ datum })}
              labelRadius={({ datum }) => labelRadius({ datum })}
              data={graphData()}
            />
          </svg>
        </div>
        <div className="flex">
          <div className="my-auto flex flex-col gap-8">
            {expensesData.map((expense) => (
              <div key={expense.name} className="flex flex-row gap-6">
                <div className="h-16 w-2 rounded" style={{ backgroundColor: expense.color }} />
                <div className="flex flex-col">
                  <Typography variant="overline" color="white">
                    {expense.name}
                  </Typography>
                  <Typography variant="h3" color="white">
                    {expense.value}
                  </Typography>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* Debts Section */}
      <div className="grid grid-cols-3 divide-x divide-gray-600 border-t-2 border-gray-600">
        <div className="flex flex-col py-10 ">
          <Typography variant="h5" color="black">
            Valor total das dívidas
          </Typography>

          <div className="flex flex-row gap-2">
            <Typography variant="h3" color="black">
              R$
            </Typography>
            <Typography variant="h3" color="white">
              {moneyFormatterWithoutCurrency(debtsTotal)}
            </Typography>
          </div>
        </div>
        <div className="flex flex-col py-10 pl-5">
          <Typography variant="h5" color="black">
            Valor médio das dívidas
          </Typography>

          <div className="flex flex-row gap-2">
            <Typography variant="h3" color="black">
              R$
            </Typography>
            <Typography variant="h3" color="white">
              {moneyFormatterWithoutCurrency(debtsAverage)}
            </Typography>
          </div>
        </div>
        <div className="flex flex-col py-10 pl-5">
          <Typography variant="h5" color="black">
            Score médio
          </Typography>

          <Typography variant="h3" color="black">
            -
          </Typography>
        </div>
      </div>
    </>
  );
};

export default ExpensesGraph;
