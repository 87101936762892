import { colorType } from "@/constants/types";

const black: colorType = "black";
const white: colorType = "white";

export const OverdueStatus = (value: any) => {
  switch (value) {
    case "paid":
      return {
        label: "Contas em dia",
        backgroundColor: "green",
        color: black,
      };
    default:
      return {
        label: "Inadimplente",
        backgroundColor: "red",
        color: white,
      };
  }
};

export const distribuitionStatus = (value: string) => {
  switch (value) {
    case "regular":
      return {
        label: "Distribuição Regular",
        backgroundColor: "green",
        color: black,
      };
    default:
      return {
        label: "Distribuição pausada",
        backgroundColor: "red",
        color: white,
      };
  }
};
