import { router } from "@inertiajs/react";
import React, { useState } from "react";
import { AiOutlineThunderbolt } from "react-icons/ai";
import { TbWallet } from "react-icons/tb";

import FaqCardComponent from "@/components/Cards/FaqComponent";
import Dropdown from "@/components/Dropdown";
import Layout from "@/components/Layout";
import Typography from "@/components/Typography";
import ExpensesGraph from "@/pages/manager/home/sections/ExpensesGraph";
import { bigNumberFormatter } from "@/utils/bigNumberFormatter";
import { moneyFormatterWithoutCurrency } from "@/utils/moneyFormat";

import {
  BarGraph,
  BreakDiv,
  CenterDiv,
  DatesSecondDiv,
  FifthDiv,
  FirstDiv,
  FourthDiv,
  Graph,
  GraphInfo,
  GraphPercentage,
  IconDiv,
  InformationBox,
  Line,
  Observation,
  Page,
  SecondDiv,
  StartDiv,
  ThirdDiv,
  Title,
  ValuesDiv,
} from "./styles";

interface HomeInfoProps {
  power_plants_count: number;
  consumers_count: number;
  energy_expenses_info: {
    total: number;
    paid: number;
    late: number;
    inadimplent: number;
  };
  expenses_info: {
    this_month: number;
    this_year: number;
  };
  consumption_info: {
    consumed: number;
    injected: number;
  };
  debts_info: {
    total: number;
    average: number;
  };
}

const yearsOptions: string[] = ["2022", "2023", "2024"];
const monthOptions: string[] = [
  "Janeiro",
  "Fevereiro",
  "Março",
  "Abril",
  "Maio",
  "Junho",
  "Julho",
  "Agosto",
  "Setembro",
  "Outubro",
  "Novembro",
  "Dezembro",
];

const Home = ({ info }: { info: HomeInfoProps }) => {
  const today = new Date();
  const [filters, setFilters] = useState({
    year: new URLSearchParams(window.location.search).get("year")
      ? String(new URLSearchParams(window.location.search).get("year"))
      : String(today.getFullYear()),
    month: new URLSearchParams(window.location.search).get("month")
      ? monthOptions[Number(new URLSearchParams(window.location.search).get("month")) - 1]
      : monthOptions[today.getMonth() - 1],
  });

  const changeFilters = ({ year = filters.year, month = filters.month }) => {
    setFilters({ year, month });
    router.reload({
      data: {
        year: year,
        month: monthOptions.indexOf(month) + 1,
      },
    });
  };

  return (
    <Layout>
      <FirstDiv>
        <StartDiv>
          <div className="flex w-full justify-end gap-4">
            <Dropdown
              options={yearsOptions}
              onSelect={(value) => {
                changeFilters({ year: value });
              }}
              defaultValue={
                new URLSearchParams(window.location.search).get("year")
                  ? String(new URLSearchParams(window.location.search).get("year"))
                  : String(today.getFullYear())
              }
            />
            <Dropdown
              options={monthOptions}
              onSelect={(value) => {
                changeFilters({ month: value });
              }}
              defaultValue={
                new URLSearchParams(window.location.search).get("month")
                  ? monthOptions[
                      Number(new URLSearchParams(window.location.search).get("month")) - 1
                    ]
                  : monthOptions[today.getMonth() - 1]
              }
            />
          </div>
          <div className="flex w-full justify-between">
            <Typography variant="h4" color="white">
              Dados gerais
            </Typography>
            <div className="col">
              <DatesSecondDiv>
                <BreakDiv>
                  <Typography variant="overline" color="primary200">
                    USINAS
                  </Typography>
                  <Typography variant="h3" color="white">
                    {info.power_plants_count}
                  </Typography>
                </BreakDiv>
                <Line />
                <BreakDiv>
                  <Typography variant="overline" color="primary200">
                    CLIENTES
                  </Typography>
                  <Typography variant="h3" color="white">
                    {info.consumers_count}
                  </Typography>
                </BreakDiv>
                <Line />
                <BreakDiv>
                  <Typography variant="overline" color="primary200">
                    REGIÃO ATENDIDA
                  </Typography>
                  <Typography variant="pLarge" color="white">
                    sem dados
                  </Typography>
                </BreakDiv>
              </DatesSecondDiv>
            </div>
          </div>
        </StartDiv>
      </FirstDiv>
      <SecondDiv>
        <Typography variant="h4" color="white">
          Financeiro
        </Typography>
        <InformationBox>
          <BreakDiv>
            <Typography variant="h6" color="grey500">
              Faturamento em {filters.month}
            </Typography>
            <ValuesDiv>
              <IconDiv>
                <TbWallet size={20} color="black" />
              </IconDiv>
              <Typography variant="h3" color="grey500">
                R$
                <Typography variant="h3" color="white">
                  {" "}
                  {moneyFormatterWithoutCurrency(info.expenses_info.this_month)}
                </Typography>
              </Typography>
            </ValuesDiv>
          </BreakDiv>
          <BreakDiv>
            <Typography variant="h6" color="grey500">
              Faturamento em {filters.year}
            </Typography>
            <ValuesDiv>
              <IconDiv>
                <TbWallet size={20} color="black" />
              </IconDiv>
              <Typography variant="h3" color="grey500">
                R$
                <Typography variant="h3" color="white">
                  {" "}
                  {moneyFormatterWithoutCurrency(info.expenses_info.this_year)}
                </Typography>
              </Typography>
            </ValuesDiv>
          </BreakDiv>
        </InformationBox>
      </SecondDiv>
      <ThirdDiv>
        <Typography variant="h4" color="white">
          Energia
        </Typography>
        <InformationBox>
          <BreakDiv>
            <Typography variant="h6" color="grey500">
              Energia injetada em {filters.month}
            </Typography>
            <ValuesDiv>
              <IconDiv>
                <AiOutlineThunderbolt size={22} color="black" />
              </IconDiv>
              <Typography variant="h3" color="white">
                {bigNumberFormatter(info.consumption_info.injected)}{" "}
                <Typography variant="h3" color="grey500">
                  kWh
                </Typography>
              </Typography>
            </ValuesDiv>
          </BreakDiv>
          <BreakDiv>
            <Typography variant="h6" color="grey500">
              Energia consumida em {filters.month}
            </Typography>
            <ValuesDiv>
              <IconDiv>
                <AiOutlineThunderbolt size={22} color="black" />
              </IconDiv>
              <Typography variant="h3" color="white">
                {bigNumberFormatter(info.consumption_info.consumed)}{" "}
                <Typography variant="h3" color="grey500">
                  kWh
                </Typography>
              </Typography>
            </ValuesDiv>
          </BreakDiv>
        </InformationBox>
        <BarGraph>
          <Typography variant="h6" color="grey500">
            Energia consumida X Energia Injetada
          </Typography>
        </BarGraph>
        <div>
          <Graph>
            <GraphPercentage
              percentage={Math.trunc(
                info.consumption_info.injected
                  ? info.consumption_info.consumed / info.consumption_info.injected
                  : 0 * 100,
              )}
            />
          </Graph>
          <GraphInfo>
            <BreakDiv>
              <Typography variant="overline" color="grey500">
                INJETADA
              </Typography>
              <Typography variant="h5" color="white">
                {bigNumberFormatter(info.consumption_info.injected)}{" "}
                <Typography variant="h5" color="grey500">
                  kWh
                </Typography>
              </Typography>
            </BreakDiv>
            <BreakDiv>
              <Typography variant="overline" color="grey500">
                CONSUMIDA
              </Typography>
              <Typography variant="h5" color="white">
                {bigNumberFormatter(info.consumption_info.consumed)}{" "}
                <Typography variant="h5" color="grey500">
                  kWh
                </Typography>
              </Typography>
            </BreakDiv>
          </GraphInfo>
        </div>
      </ThirdDiv>
      <FourthDiv>
        <Title>
          <Typography variant="h4" color="white">
            Contas e valores
          </Typography>
          <Observation>
            <Typography variant="pLarge" color="white">
              Dados sobre as contas pagas, atrasadas e inadimplentes e valores.
            </Typography>
          </Observation>
        </Title>

        <ExpensesGraph
          payedExpenses={info.energy_expenses_info.paid}
          lateExpenses={info.energy_expenses_info.late}
          overdueExpenses={info.energy_expenses_info.inadimplent}
          debtsTotal={info.debts_info.total}
          debtsAverage={info.debts_info.average}
        />
      </FourthDiv>
      <FifthDiv>
        <Typography variant="h4" color="black">
          Região de atendimento
        </Typography>
        <CenterDiv>
          <Typography variant="h6" color="black">
            Ainda não há dados sobre a região de atendimento.
          </Typography>
        </CenterDiv>
      </FifthDiv>
      <Page>
        <FaqCardComponent variant="questionSection" />
      </Page>
    </Layout>
  );
};

export default Home;

// export const getServerSideProps: GetServerSideProps = async ctx => {
//   const { currentUser } = parseCookies(ctx);
//
//   const { id } = await JSON.parse(currentUser);
//
//   const res = await Managers.homeInfo(id, ctx);
//   const information = res.data;
//
//   return {
//     props: {
//       info: information,
//     },
//   };
// };
