import styled from "styled-components";

import { CirclesProps } from "./types";

const backgroundImage = ({ variant }: CirclesProps) => {
  if (variant == "green") {
    return "linear-gradient(30deg, #61cc77,#69d07d, #69d580, #77e18c,#91e5a6)";
  }
  if (variant == "grey") {
    return "linear-gradient(30deg, #2f524c, #2e514b, #2f524c, #385a54, #40625c)";
  }
  if (variant == "red") {
    return "linear-gradient(30deg, #d86050, #db6655, #d9695b, #da7466, #dc8478)";
  }
};

export const Containter = styled.div<CirclesProps>`
  width: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 14px 0px 0px 14px;

  div {
    width: 100%;
    height: 100%;
    display: flex;
  }

  .circle {
    min-width: 95px;
    min-height: 95px;
    border-radius: 50%;
    background-image: ${(props) => backgroundImage(props)};
    margin: -14px;
  }
`;
