import React from "react";

import Typography from "@/components/Typography";
import moneyFormatter from "@/utils/moneyFormat";

import { CommonValueDiv, Container, FinalValueDiv, GeedeeValueDiv } from "./styles";
import { MonthCardProps } from "./types";

const MonthCard = ({ month, distributorPrice, price, discount }: MonthCardProps) => {
  return (
    <Container>
      <div style={{}}>
        <Typography variant="btnSmall" color="primary500">
          {month}
        </Typography>
      </div>
      <div>
        <CommonValueDiv>
          <Typography variant="btnSmall" color="primary500">
            {moneyFormatter(distributorPrice)}
          </Typography>
        </CommonValueDiv>
        <GeedeeValueDiv>
          <Typography variant="btnSmall" color="primary500">
            {moneyFormatter(price)}
          </Typography>
        </GeedeeValueDiv>
        <FinalValueDiv>
          <Typography variant="btnSmall" color="white">
            {moneyFormatter(discount)}
          </Typography>
        </FinalValueDiv>
      </div>
    </Container>
  );
};

export default MonthCard;
