import styled from "styled-components";

import theme from "@/constants/theme";

export const Container = styled.div`
  width: 100%;
  height: 94px;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 24px;
  overflow-x: auto;
  justify-content: space-between;
  grid-template-columns: repeat(12, minmax(94px, 1fr));
`;

export const Line = styled.div`
  height: 62px;
  width: 1px;
  background-color: ${theme.colors.grey100};
`;

export const Button = styled.button`
  width: 110px;
  height: 40px;
  border: none;
  border-radius: 56px;
  color: white;
  background-color: ${theme.colors.black};
  margin: 0px 24px 0px 24px;
`;
export const PersonInfoDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InfoDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  min-width: 200px;
`;
export const ImageDiv = styled.div`
  width: 30px;
  height: 30px;
  background-color: ${theme.colors.grey100};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 1rem;
`;

export const IconDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0rem 0.5rem 0rem 0.5rem;
`;

export const WarningDiv = styled.div`
  .green {
    background-color: ${theme.colors.success200};
    color: black;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    padding: 0rem 1.2rem;
  }

  .red {
    background-color: ${theme.colors.danger200};
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    padding: 0rem 1.2rem;
    color: white;
  }
`;
