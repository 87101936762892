import { router, useForm } from "@inertiajs/react";
import React, { FormEvent, useContext, useState } from "react";

import SimpleButton from "@/components/Buttons/SimpleButton";
import { StatusType } from "@/components/Buttons/SimpleButton/types";
import Logo from "@/components/Logo";
import TextFieldComponent from "@/components/TextField";
import Typography from "@/components/Typography";
import { AlertContext } from "@/contexts/Alert";

import { Access, Container, Form, Img, Infos, LogoContainer } from "../forgot/styles";

export default function Recover({ reset_password_token }: any) {
  const { showAlert } = useContext<any>(AlertContext);

  const [buttonStatus, setButtonStatus] = useState<StatusType>("enable");

  const { data, setData, patch } = useForm({
    password: "",
    password_confirmation: "",
    reset_password_token: reset_password_token,
  });

  const submitPasswordData = (e: FormEvent) => {
    e.preventDefault();
    if (data.password !== data.password_confirmation) {
      showAlert({
        message: "Senhas não são iguais. Tente novamente.",
        type: "error",
      });
      return;
    }

    setButtonStatus("loading");
    patch("/new_users/password", {
      onFinish: () => {
        setButtonStatus("enable");
      },
      onSuccess: () => {
        showAlert({
          message: "Senha alterada com sucesso!",
          type: "success",
        });
      },
      onError: (e) => {
        showAlert({
          message: e,
          type: "error",
        });
      },
    });
  };

  return (
    <Container>
      <div className="grid h-full grid-cols-1 justify-between gap-2 md:grid-cols-3">
        <Infos className="col-span-2 grid grid-cols-1 md:grid-cols-3">
          <LogoContainer onClick={() => router.get("/")}>
            <Logo size="h5" />
          </LogoContainer>
          <Access className="col-span-2 mx-auto w-full">
            <Form onSubmit={submitPasswordData} className="w-full">
              <div className="header">
                <Typography variant="h3" color="grey800">
                  Escolher nova senha
                </Typography>
                <Typography variant="pLarge" color="grey500">
                  Decida sua nova senha e preencha o formulário abaixo.
                </Typography>
              </div>
              <div className="forms-input">
                <div className="input">
                  <TextFieldComponent
                    disabled={false}
                    label="Nova Senha"
                    error={false}
                    password={true}
                    required
                    value={data.password}
                    onChange={(e) => setData("password", e.target.value)}
                  />
                </div>
                <div className="input">
                  <TextFieldComponent
                    disabled={false}
                    label="Repita a nova senha"
                    error={false}
                    password={true}
                    value={data.password_confirmation}
                    required
                    onChange={(e) => setData("password_confirmation", e.target.value)}
                  />
                </div>
              </div>
              <div className="button">
                <SimpleButton variant="cta" status={buttonStatus} label="Alterar" />
              </div>
            </Form>
          </Access>
        </Infos>
        <Img className="invisible w-full md:visible">
          <div className="image w-full" />
        </Img>
      </div>
    </Container>
  );
}
