import React from "react";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";
import { ThreeDots } from "react-loader-spinner";

import Typography from "@/components/Typography";

import { Container } from "./styles";
import { SimpleButtonProps } from "./types";

const SimpleButton = ({
  variant = "cta",
  status = "enable",
  label,
  backgroundColor,
  onClick,
  className,
}: SimpleButtonProps) => {
  return (
    <Container
      disabled={status == "loading" || status == "disabled" ? true : false}
      variant={variant}
      status={status}
      label=""
      onClick={onClick}
      $backgroundColor={backgroundColor}
      className={className}
    >
      {variant == "back" && (
        <div className="icon-left">
          <FiArrowLeft />
        </div>
      )}
      <div className="info-button">
        {status != "loading" ? (
          <Typography
            color={backgroundColor ? "black" : status == "disabled" ? "grey500" : "white"}
            variant="btnLarge"
          >
            {label}
          </Typography>
        ) : (
          <div className="loading-size">
            <ThreeDots color="white" />
          </div>
        )}
      </div>
      {variant != "simple" && variant != "back" && status !== "loading" ? (
        <div className="icon-right">
          <FiArrowRight />
        </div>
      ) : (
        <></>
      )}
    </Container>
  );
};

export default SimpleButton;
