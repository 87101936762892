import { Disclosure, Transition } from "@headlessui/react";
import React from "react";
import { FiMinus, FiPlus } from "react-icons/fi";

import Typography from "@/components/Typography";

import { Container } from "./styles";
import { DropDownCardProps } from "./types";

const DropDownCard = ({ label, text }: DropDownCardProps) => {
  return (
    <Container>
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className="drop-down-button">
              <Typography variant="h6" color="black">
                {label}
              </Typography>
              <div className="icon-circle">{!open ? <FiPlus /> : <FiMinus />}</div>
            </Disclosure.Button>
            <Transition
              enter="transform transition duration-500 ease-custom"
              enterFrom="-translate-y-1/2 scale-y-0 opacity-0"
              enterTo="translate-y-0 scale-y-100 opacity-100"
              leave="transform transition duration-500 ease-custom"
              leaveFrom="translate-y-0 scale-y-100 opacity-100"
              leaveTo="-translate-y-1/2 scale-y-0 opacity-0"
            >
              <Disclosure.Panel className="drop-down-text">
                <Typography variant="pLarge" color="white">
                  {text}
                </Typography>
              </Disclosure.Panel>
            </Transition>
          </>
        )}
      </Disclosure>
    </Container>
  );
};

export default DropDownCard;
