import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  alig-items: center;
  width: 100%;
  position: relative;
  background-color: ${({ theme }) => theme.colors.tertiary100};
  padding: 0.5rem 2rem;
  border-radius: 1.5rem;
`;

export const TextDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (min-width: 1024px) {
    gap: 1.6rem;
  }
`;

export const TextDivInfo = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
`;

export const MonthDiv = styled.div`
  display: flex;
  gap: 0.5em;
  align-items: center;
  margin-top: 1em;
  overflow: auto;
  padding: 0 0.5rem;
  scroll-behavior: smooth;
  width: 80%;

  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 1024px) {
    margin-top: 2.5rem;
  }
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0.5rem;
  top: 2rem;
  gap: 0.5em;
`;

export const Button = styled.div`
  height: 3rem;
  width: 3rem;
  background-color: ${({ theme }) => theme.colors.black};
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
